import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cover: null,
  header: null,
  group: null,
  groups: null,
  innerGroups: null,
  products: null
};

export const groupSlice = createSlice({
  name: 'modificerSlice',
  initialState: initialState,
  reducers: {
    updateGroup: (state, action) => {
      state.group = action.payload;
    },
    updateGroups: (state, action) => {
      state.groups = action.payload;
    },
    updateCover: (state, action) => {
      state.cover = action.payload;
    },
    updateHeader: (state, action) => {
      state.header = action.payload;
    },
    updateInnerGroups: (state, action) => {
      state.innerGroups = action.payload;
    },
    updateProducts: (state, action) => {
      state.products = action.payload;
    }
  }
});

export const {
  updateGroups,
  updateGroup,
  updateCover,
  updateHeader,
  updateInnerGroups,
  updateProducts,
  updateAllGroupsLength,
  updateAllProductsLength
} = groupSlice.actions;

export default groupSlice.reducer;
