/** @format */

import { useEffect, useState } from 'react';
import styles from './Orders.module.css';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@material-ui/core';
import { useOrderAPI } from './hooks/useOrderAPI';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import { getApi } from 'api/axios';
import { useRef } from 'react';
import QueryBuilder from 'components/common/QueryBuilder/QueryBuilder';
import { useSelector } from 'react-redux';
import { useNotificationsApi } from 'pages/SuperAdmin/Notifications/hooks/useNotificationsApi';
import NotificationDialog from 'components/common/NotificationDialog/NotificationDialog';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import WarningIcon from '@mui/icons-material/Warning';
import { amber } from '@mui/material/colors';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircle } from '@mui/icons-material';
import { Grow } from '@mui/material';

const Orders = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showAll, setShowAll] = useState(false);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);

  const { fetchOrders, orders, ordersLength, loading } = useOrderAPI();

  const { fetchAdminNotification } = useNotificationsApi();

  useEffect(() => {
    fetchAdminNotification();
  }, []);

  const getShowAllQueryString = () => {
    let queryParams = new URLSearchParams(search);
    queryParams.set('page', 1);
    queryParams.set('size', ordersLength);
    return queryParams.toString();
  };
  const handleShowAll = () => {
    setShowAll(!showAll);

    navigate(`/shops/${shopId}/administration/orders?${getShowAllQueryString()}`);
  };

  const renderSwitch = (param) => {
    switch (param) {
      case 'NO_INVOICE':
        return (
          <Tooltip title={'Nincs számlázva'}>
            <RemoveCircleIcon sx={{ color: 'grey', fontSize: 30 }} />
          </Tooltip>
        );
      case 'PARTIAL_INVOICE':
        return (
          <Tooltip title={'Részben számlázva'}>
            <WarningIcon sx={{ color: amber[500], fontSize: 30 }} />
          </Tooltip>
        );
      case 'FAILED_INVOICE':
        return (
          <Tooltip title={'Sikertelenül számlázva'}>
            <CancelIcon sx={{ color: 'red', fontSize: 30 }} />
          </Tooltip>
        );
      case 'INVOICED':
        return (
          <Tooltip title={'Sikeresen számlázva'}>
            <CheckCircle sx={{ color: '#7ac142', fontSize: 30 }} />
          </Tooltip>
        );
      default:
        return <div>Hiba</div>;
    }
  };

  const handleStatusTranslate = (status) => {
    if (status === 'PENDING') {
      return 'FÜGGŐBEN';
    }
    if (status === 'RECEIVED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'INITIATED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'CANCELLED') {
      return 'MEGSZAKÍTOTT FIZETÉS';
    }
    if (status === 'CONFIRMED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'PAID') {
      return 'FIZETVE';
    }
    if (status === 'INVOICED') {
      return 'SZÁMLÁZVA';
    }
    if (status === 'SHIPPING') {
      return 'KISZÁLLÍTÁS ALATT';
    }
    if (status === 'DONE') {
      return 'TELJESÍTVE';
    }
    if (status === 'REJECTED') {
      return 'VISSZAUTASÍTVA';
    }
    if (status === 'REJECTED_PAYMENT') {
      return 'SIKERTELEN FIZETÉS';
    }
    if (status === 'REJECTED_INVOICE') {
      return 'SIKERTELEN SZÁMLÁZÁS';
    }
    if (status === 'REJECTED_DELIVERY') {
      return 'SIKERTELEN KÉZBESÍTÉS';
    }
  };

  const handleStatusStyle = (status) => {
    if (status === 'PENDING') {
      return styles?.pending;
    }
    if (status === 'PAID') {
      return styles?.paid;
    }
    if (status === 'INVOICED') {
      return styles?.invoiced;
    }
    if (status === 'SHIPPING') {
      return styles?.shipping;
    }
    if (status === 'DONE') {
      return styles?.done;
    }
    if (status === 'REJECTED') {
      return styles?.rejected;
    }
    if (status === 'REJECTED_PAYMENT') {
      return styles?.rejected;
    }
    if (status === 'REJECTED_INVOICE') {
      return styles?.rejected;
    }
    if (status === 'REJECTED_DELIVERY') {
      return styles?.rejected;
    }
  };

  const handleGetPdf = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(
        `/admin/shops/${shopId}/orders/export/pdf${
          search && search?.includes('size')
            ? search.split('size=')[0] + 'size=100000&' + search.split('size=')[1]?.split('&')[1]
            : search
        }${search ? '' : '?size=100000&page=1'}`,
        {
          headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/pdf'
          },
          responseType: 'blob'
        }
      );

      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));

      const a = linkRef.current;
      a.download = `Rendeles_lista_${new Date().toLocaleDateString('hu-HU')}.pdf`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      console.log(e);
    }
  };

  const handleGetCSV = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(
        `/admin/shops/${shopId}/orders/export/pdf${
          search && search?.includes('size')
            ? search.split('size=')[0] + 'size=100000&' + search.split('size=')[1]?.split('&')[1]
            : search
        }${search ? '' : '?size=100000&page=1'}`,
        {
          headers: {
            Accept: 'application/csv',
            'Content-Type': 'application/csv'
          },
          responseType: 'blob'
        }
      );

      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/csv; charset=UTF-8' }));

      const a = linkRef.current;
      a.download = `Rendeles_lista.csv`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      console.log(e);
    }
  };

  const routeChange = (id) => {
    let path = `${id}`;
    navigate(path);
  };

  const refresh = () => {
    fetchOrders({ queryString: search });
  };
  const linkRef = useRef(null);

  useEffect(() => {
    refresh();
    let queryParams = new URLSearchParams(search);

    if (queryParams.get('size') !== ordersLength) {
      setShowAll(false);
    }
  }, [search]);

  const path = useLocation().pathname;

  const resetQueryString = () => {
    navigate(path);
  };
  return (
    <Container maxWidth={false} className={styles.root}>
      <CssBaseline />
      <a ref={linkRef} />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography style={{ fontFamily: 'var(--font-main)' }} variant='body1'>
          Rendelések
        </Typography>
      </Breadcrumbs>
      <Grow in={true}>
        <Container maxWidth={false} className={styles.container}>
          <QueryBuilder
            itemsLength={ordersLength}
            siteType={'order'}
            showAll={showAll}
            reset={resetQueryString}
            refresh={refresh}>
            <table className={styles.headerTop}>
              <tbody>
                <tr width='100%' className={styles.searchBar}>
                  <td
                    className={styles.tableCell}
                    style={{
                      color: 'whitesmoke',
                      textAlign: 'center',
                      display: 'flex',
                      gap: '1rem'
                    }}>
                    <Tooltip title={'Rendelés összesítő lista letöltése a szűrő feltételek szerint'}>
                      <div>
                        <Button
                          variant='outlined'
                          disabled={orders?.length < 1}
                          style={{
                            backgroundColor: 'var(--primary-background-content)',
                            color: 'var(--primary-main)',
                            margin: 0,
                            border: `2px solid var(--primary-background-primary)`,
                            fontFamily: 'var(--font-main) !important'
                          }}
                          onClick={() => handleGetPdf(orders)}>
                          <PictureAsPdfIcon />
                          PDF letöltés
                        </Button>
                      </div>
                    </Tooltip>
                    <Tooltip title={'Rendelés összesítő lista letöltése a szűrő feltételek szerint'}>
                      <div>
                        <Button
                          variant='outlined'
                          disabled={orders?.length < 1}
                          style={{
                            backgroundColor: 'var(--primary-background-content)',
                            color: 'var(--primary-main)',
                            margin: 0,
                            border: `2px solid var(--primary-background-primary)`,
                            fontFamily: 'var(--font-main) !important'
                          }}
                          onClick={() => handleGetCSV()}>
                          <FileDownloadIcon />
                          CSV letöltés
                        </Button>
                      </div>
                    </Tooltip>
                  </td>
                </tr>
              </tbody>
            </table>

            <table className={styles.header}>
              <thead>
                <tr width='100%' style={{ fontFamily: 'var(--font-main)' }}>
                  <td className={styles.hideXs} style={{ width: '5%' }}>
                    <Typography className={styles.heading}>#</Typography>
                  </td>
                  <td className={styles.hideXs} style={{ width: '10%' }}>
                    <Typography className={styles.heading}>Azonosító</Typography>
                  </td>
                  <td className={styles.heading}>
                    <Typography className={styles.heading}>Ügyfél neve</Typography>
                  </td>
                  <td className={styles.heading}>
                    <Typography className={styles.heading}>Rendelés dátuma</Typography>
                  </td>
                  <td className={styles.hideXs}>
                    <Typography className={styles.heading}>Kiszállítási cím</Typography>
                  </td>
                  <td className={styles.hideXs}>
                    <Typography className={styles.heading}>Összeg</Typography>
                  </td>
                  <td className={styles.hideXs}>
                    <Typography className={styles.heading}>Számlázás</Typography>
                  </td>
                  <td className={styles.tableCell}>
                    <Typography className={styles.heading}>Státusz</Typography>
                  </td>
                </tr>
              </thead>
            </table>
            <div className={styles.orderContainer}>
              <table className={styles.listitemtable}>
                <tbody>
                  {loading ? (
                    <tr>
                      <td>
                        <LoadIndicator color={'var(--primary-light)'} height={800} />
                      </td>
                    </tr>
                  ) : orders?.length > 0 ? (
                    orders?.map((order, idx) => (
                      <tr
                        className={styles.listItem}
                        width='100%'
                        key={order.id}
                        onClick={() => {
                          routeChange(order.id);
                        }}>
                        <td className={styles.hideXs} style={{ width: '5%' }}>
                          <Typography className={styles.tableCell}>{idx + 1}</Typography>
                        </td>
                        <td className={styles.hideXs} style={{ width: '10%' }}>
                          <Typography className={styles.tableCell}>{order?.id}</Typography>
                        </td>
                        <td className={styles.tableCell}>
                          <Typography className={styles.tableCell}>{order?.billingInfo?.customerName}</Typography>
                        </td>
                        <td className={styles.tableCell}>
                          {order?.createdAt && (
                            <Typography className={styles.tableCell}>
                              {new Date(order?.createdAt).toLocaleDateString('hu-HU', {
                                hour: '2-digit',
                                minute: '2-digit',
                                seconds: '2-digit'
                              })}
                            </Typography>
                          )}
                        </td>

                        <td className={styles.hideXs}>
                          <Typography className={styles.tableCell}>{order?.deliveryTarget?.deliveryTarget}</Typography>
                        </td>
                        <td className={styles.hideXs}>
                          <Typography className={styles.tableCell}>{order?.totalPrice?.grossAmount} Ft</Typography>
                        </td>
                        <td className={styles.hideXs}>
                          <Typography className={styles.tableCell} component='div'>
                            {renderSwitch(order?.invoiceState)}
                          </Typography>
                        </td>
                        <td className={styles.tableCell}>
                          <Typography
                            style={{
                              fontFamily: 'var(--font-main)'
                            }}
                            className={handleStatusStyle(order?.status)}>
                            {handleStatusTranslate(order?.status)}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : !user?.ownedShops?.find((shop) => shop.id == shopId) &&
                    !user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes?.includes('VIEW_ORDERS') ? (
                    <tr
                      style={{
                        margin: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <td>
                        <Typography
                          variant='h5'
                          style={{
                            color: 'var(--primary-light)'
                          }}>
                          A rendelések megtekintéséhez rendelkeznie kell a <b>VIEW_ORDERS</b> jogosultsággal.
                        </Typography>
                      </td>
                    </tr>
                  ) : (
                    <tr
                      style={{
                        margin: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <td>
                        <Typography
                          variant='h5'
                          style={{
                            color: 'var(--primary-light)'
                          }}>
                          Nem érkezett még rendelés.
                        </Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </QueryBuilder>

          <button
            className={styles.allButton}
            onClick={() => {
              handleShowAll();
            }}>
            {showAll ? 'Kevesebb rendelés' : 'Összes rendelés'}
          </button>
        </Container>
      </Grow>
      <NotificationDialog />
    </Container>
  );
};

export default Orders;
