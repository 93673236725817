import { TextField } from '@mui/material';
import React from 'react';
import styles from './StringEditor.module.css';

const StringEditor = ({ option, setOption }) => {
  return (
    <div className={styles.stringEditorWrapper}>
      <TextField
        variant='outlined'
        placeholder={`Enter ${option.displayName.toLowerCase()}...`}
        fullWidth
        size='small'
        InputProps={{
          className: styles.input,
          style: { fontFamily: 'var(--font-main)' }
        }}
        onChange={(e) => setOption({ ...option, value: `${option.name}=${e.target.value}` })}
      />
    </div>
  );
};

export default StringEditor;
