import React, { useEffect, useState } from 'react';
import DateEditor from './DateEditor/DateEditor';
import DeliveryTargetEditor from './DeliveryTargetEditor/DeliveryTargetEditor';
import KeyValueEditor from './KeyValueEditor/KeyValueEditor';
import SelectEditor from './SelectEditor/SelectEditor';
import StringEditor from './StringEditor/StringEditor';
import GroupNameEditor from './GroupNameEditor/GroupNameEditor';
import ReportingDateEditor from './ReportingDateEditor/ReportingDateEditor';
import StatusEditor from './StatusEditor/StatusEditor';
import styles from './QueryParamEditor.module.css';

const QueryParamEditor = ({ tempOption, setTempOption }) => {
  // types = ['string', 'date', 'select', 'key-value'];

  const [editor, setEditor] = useState(null);

  const getEditor = (tempOption) => {
    const editors = {
      string: <StringEditor option={tempOption} setOption={setTempOption} />,
      date: <DateEditor option={tempOption} setOption={setTempOption} />,
      reportingDate: <ReportingDateEditor option={tempOption} setOption={setTempOption} />,
      select: <SelectEditor option={tempOption} setOption={setTempOption} />,
      status: <StatusEditor option={tempOption} setOption={setTempOption} />,
      'key-value': <KeyValueEditor option={tempOption} setOption={setTempOption} />,
      deliveryTarget: <DeliveryTargetEditor option={tempOption} setOption={setTempOption} />,
      groupName: <GroupNameEditor option={tempOption} setOption={setTempOption} />
    };

    return editors[tempOption.type] || null;
  };

  useEffect(() => {
    if (tempOption) {
      setEditor(getEditor(tempOption));
    }
  }, [tempOption]);

  return (
    <div className={styles.editorWrapper} onClick={(e) => e.stopPropagation()}>
      {editor || <div className={styles.placeholder}>Select an option...</div>}
    </div>
  );
};

export default QueryParamEditor;
