/** @format */

import { useEffect, useState } from 'react';
import styles from './OrderDetails.module.css';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Breadcrumbs, Button, Dialog, DialogTitle, Link, Slide, TextField, useMediaQuery } from '@material-ui/core';
import OrderList from './OrderList/OrderList';
import { useOrderAPI } from '../hooks/useOrderAPI';
import { usePaymentAPI } from './hooks/usePaymentAPI';
import { useInvoiceAPI } from './hooks/useInvoiceAPI';
import { useNavigate, useParams } from 'react-router-dom';
import CheckIcon from 'components/common/CheckIcon/CheckIcon';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import DetailsCard from 'components/PhotoAdminComponents/Orders/DetailsCard/DetailsCard';
import { useDeliveryAPI } from './hooks/useDeliveryAPI';
import OrderHistoryWidget from 'components/PhotoAdminComponents/Orders/OrderHistoryWidget/OrderHistoryWidget';
import HistoryIcon from '@mui/icons-material/History';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { useRef } from 'react';
import { getApi } from 'api/axios';
import HistoryViewer from 'components/PhotoAdminComponents/Orders/HistoryViewer/HistoryViewer';
import sizes from 'constants/sizes';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { useSelector } from 'react-redux';
import { IconButton } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/Download';
import { Tooltip } from '@mui/material';
import StatusModifier from 'components/PhotoAdminComponents/Orders/StatusModifier/StatusModifier';

const OrderDetails = (props) => {
  const { id } = useParams();
  const [downloadProgress, setDownloadProgress] = useState();

  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);

  const navigate = useNavigate();
  const [orderHistoryVisible, setOrderHistoryVisible] = useState(false);
  const toggleOrderHistoryVisible = () => setOrderHistoryVisible(!orderHistoryVisible);
  const [paymentHistoryVisible, setPaymentHistoryVisible] = useState(false);
  const togglePaymentHistoryVisible = () => setPaymentHistoryVisible(!paymentHistoryVisible);
  const [reprintDialogOpen, setReprintDialogOpen] = useState(false);
  const toogleReprintDialogOpen = () => setReprintDialogOpen(!reprintDialogOpen);

  const desktop = useMediaQuery(`(min-width:${sizes.SM.toString()}px)`);

  const { fetchOrder, order, loading, putOrder } = useOrderAPI();

  const { fetchPayment, payment } = usePaymentAPI();
  const { fetchDelivery, delivery, handleReprint, handleResend } = useDeliveryAPI();
  const { fetchInvoice, invoice, setInvoice, invoiceLoading, invoiceVendors } = useInvoiceAPI();

  const getDateString = (date) => {
    return new Date(date).toLocaleDateString('hu-HU', {
      hour: '2-digit',
      minute: '2-digit',
      seconds: '2-digit'
    });
  };

  useEffect(() => {
    fetchOrder({ id: id });
  }, [fetchOrder, id]);

  const [emailDeliveryFound, setEmailDeliveryFound] = useState(null);
  const [postDeliveryFound, setPostDeliveryFound] = useState(null);
  const [lelkesSelfDeliveryFound, setLelkesSelfDeliveryFound] = useState(null);
  const [postAndLelkesDeliveryFound, setPostAndLelkesDeliveryFound] = useState(null);
  const [deliveryStates, setDeliveryStates] = useState({});

  const [newEmail, setNewEmail] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const getStatuses = () => {
    let lastPOSTStatus = null;
    let lastPOSTDelivery = null;
    let last_LELKES_SELF_Status = null;
    let last_LELKES_SELF_Delivery = null;
    let last_EMAIL_Status = null;
    let last_EMAIL_Delivery = null;
    let last_POST_AND_LELKES_PRINT_Status = null;
    let last_POST_AND_LELKES_PRINT_Delivery = null;
    let states = deliveryStates;

    order?.deliveryReferences?.forEach((deliveryReference) => {
      if (deliveryReference?.type === 'POST') {
        lastPOSTStatus = `#${deliveryReference?.id}:${deliveryReference?.status} - ${getDateString(
          deliveryReference?.createdAt
        )}`;
        lastPOSTDelivery = deliveryReference;
      }
      if (deliveryReference?.type === 'LELKES_SELF_PRINT') {
        last_LELKES_SELF_Status = `#${deliveryReference?.id}:${deliveryReference?.status} - ${getDateString(
          deliveryReference?.createdAt
        )}`;
        last_LELKES_SELF_Delivery = deliveryReference;
      }
      if (deliveryReference?.type === 'EMAIL') {
        last_EMAIL_Status = `#${deliveryReference?.id}:${deliveryReference?.status} - ${getDateString(
          deliveryReference?.createdAt
        )}`;
        last_EMAIL_Delivery = deliveryReference;
      }
      if (deliveryReference?.type === 'POST_AND_LELKES_PRINT') {
        last_POST_AND_LELKES_PRINT_Status = `#${deliveryReference?.id}:${deliveryReference?.status} - ${getDateString(
          deliveryReference?.createdAt
        )}`;
        last_POST_AND_LELKES_PRINT_Delivery = deliveryReference;
      }
    });

    if (lastPOSTStatus) {
      states = {
        ...states,
        post: {
          title: 'Nyomtatott',
          info: lastPOSTStatus
        }
      };
      setPostDeliveryFound(lastPOSTDelivery);
    }
    if (last_LELKES_SELF_Status) {
      states = {
        ...states,
        lelkes_self: {
          title: 'Saját termék',
          info: last_LELKES_SELF_Status
        }
      };
      setLelkesSelfDeliveryFound(last_LELKES_SELF_Delivery);
    }
    if (last_EMAIL_Status) {
      states = {
        ...states,
        email: {
          title: 'Digitális',
          info: last_EMAIL_Status
        }
      };
      setEmailDeliveryFound(last_EMAIL_Delivery);
    }
    if (last_POST_AND_LELKES_PRINT_Status) {
      states = {
        ...states,
        post_and_lelkes: {
          title: 'Nyomtatott és saját termék',
          info: last_POST_AND_LELKES_PRINT_Status
        }
      };
      setPostAndLelkesDeliveryFound(last_POST_AND_LELKES_PRINT_Delivery);
    }
    setDeliveryStates(states);
  };

  useEffect(() => {
    getStatuses();
  }, [order]);

  const resendDigital = () => {
    let newOrder = order;
    newOrder.customerContact = {
      ...newOrder.customerContact,
      email: newEmail
    };
    handleResend({
      id: emailDeliveryFound?.deliveryId,
      body: order
    });
    setNewEmail('');
  };

  const handleStates = (status) => {
    if (status === 'PENDING') {
      return 'FÜGGŐBEN';
    }
    if (status === 'PAID') {
      return 'FIZETVE';
    }
    if (status === 'CONFIRMED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'RECEIVED') {
      return 'FIZETÉSRE VÁR';
    }
    if (status === 'INVOICED') {
      return 'SZÁMLÁZVA';
    }
    if (status === 'SHIPPING') {
      return 'KISZÁLLÍTÁS ALATT';
    }
    if (status === 'DONE') {
      return 'TELJESÍTVE';
    }
    if (status === 'REJECTED') {
      return 'VISSZAUTASÍTVA';
    }
    if (status === 'CANCELLED') {
      return 'VISSZAUTASÍTVA';
    }
    if (status === 'PRINTED') {
      return 'KIÁLLÍTVA';
    }
  };

  const handleGetPdf = async (id) => {
    setDownloadProgress(true);
    try {
      const res = await (
        await getApi({
          baseUrl: window._env_.REACT_APP_QDAK_PAYMENT_URL
        })
      ).get(`/admin/shops/${shopId}/invoices/${id}/document`, {
        headers: {
          'Content-Type': 'application/pdf'
        },
        responseType: 'blob'
      });
      setDownloadProgress(false);
      const href = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));

      const a = linkRef.current;
      a.download = `rendeles_${order?.id}_szamla_.pdf`;
      a.href = href;
      a.click();
      a.href = '';
    } catch (e) {
      setDownloadProgress(false);
      console.log(e);
    }
  };
  const orderDetailsData = {
    cardTitle: 'Megrendelés',
    icon: <HistoryIcon />,
    tooltipText: 'A megrendelés státuszának változásait itt követheted nyomon',
    buttonText: desktop && 'Történet megtekintése',
    handler: toggleOrderHistoryVisible,
    rows: [
      {
        title: 'Rendelés azonosító',
        info: order?.id
      },
      {
        title: 'Megrendelő',
        info: order?.billingInfo?.customerName
      },
      {
        title: 'Telefonszám',
        info: order?.customerContact?.phone
      },
      {
        title: 'Email cím',
        info: order?.customerContact?.email
      },
      {
        title: 'Megrendelő címe/székhelye',
        info: `${order?.billingInfo?.address?.country}, ${order?.billingInfo?.address?.zipCode} ${order?.billingInfo?.address?.city}, ${order?.billingInfo?.address?.street}`
      },
      {
        title: 'Rendelés dátuma',
        info:
          order?.createdAt !== undefined
            ? new Date(order?.createdAt).toLocaleDateString('hu-HU', {
                hour: '2-digit',
                minute: '2-digit',
                seconds: '2-digit'
              })
            : 'No Date'
      },
      {
        title: 'Rendelés lejárata',
        info: order?.deliveryTarget?.expirationDate
          ? new Date(order?.deliveryTarget?.expirationDate).toLocaleDateString('hu-HU', {
              hour: '2-digit',
              minute: '2-digit',
              seconds: '2-digit'
            })
          : 'No Date'
      },
      {
        title: 'Kosár azonosító',
        info: order?.cartId
      }
    ]
  };

  const paymentDetailsData = {
    cardTitle: 'Fizetés',
    icon: <HistoryIcon />,
    tooltipText: 'A fizetés státuszának változásait itt követheted nyomon',
    buttonText: desktop && 'Történet megtekintése',
    buttonDisabled: !payment,
    requiredState: 'PENDING',
    handler: togglePaymentHistoryVisible,
    rows: [
      {
        title: 'Fizetés azonosítója',
        info: order?.paymentTrxId ? order?.paymentTrxId : '-'
      },
      {
        title: 'Fizetési mód',
        info: payment ? `${order?.paymentMethod}, ${order?.paymentMode || '-'}` : '-'
      },
      {
        title: 'Külső azonosító',
        info: payment?.externalId ? payment?.externalId : '-'
      },
      {
        title: 'Fizetendő összeg',
        info: `${order && order?.totalPrice?.grossAmount} HUF`
      },
      {
        title: 'Fizetés időpontja',
        info: payment
          ? new Date(payment.createdAt).toLocaleDateString('hu-HU', {
              hour: '2-digit',
              minute: '2-digit',
              seconds: '2-digit'
            })
          : ' - '
      },
      {
        title: 'Fizetés Státusza',
        info: (
          <span
            style={{
              color: (payment?.status === 'REJECTED' || payment?.status === 'CANCELLED') && 'var(--primary-error)'
            }}>
            {order?.status === 'DONE'
              ? handleStates('DONE')
              : payment?.status
              ? handleStates(payment?.status)
              : 'Fizetésre vár'}
          </span>
        )
      }
    ]
  };

  const shippingDetailsData = {
    cardTitle: 'Szállítás',
    icon: <ForwardToInboxIcon />,
    tooltipText: 'Digitális képek újboli kézbesítése',
    buttonText: desktop && 'Újraküldés',
    handler: () => setIsOpen(true),
    rows: [
      {
        title: 'Szállítási mód',
        info: 'Átvétel az iskolában'
      },
      {
        title: 'Szállítási cím',
        info: order?.deliveryTarget?.deliveryTarget
      },
      ...Object.values(deliveryStates)
    ]
  };

  const billingDetailsData = {
    cardTitle: 'Számlázás',
    requiredState: 'INVOICED',
    rows: [
      {
        title: 'Számlázási név',
        info: order?.billingInfo?.companyName ? order?.billingInfo?.companyName : order?.billingInfo?.customerName
      },
      {
        title: 'Számlázási cím',
        info: `${order?.billingInfo?.address?.country}, ${order?.billingInfo?.address?.zipCode}, ${order?.billingInfo?.address?.city}, ${order?.billingInfo?.address?.street}`
      }
    ]
  };

  useEffect(() => {
    if (!(typeof order?.paymentTrxId === 'undefined')) {
      fetchPayment({ id: order?.paymentTrxId });
    }
    if (!(typeof order?.billingTransactionIds === 'undefined')) {
      setInvoice([]);
      fetchInvoice({ IDS: order?.billingTransactionIds });
      /*order?.billingTransactionIds.forEach((el) => {
        fetchInvoice({ id: el });
      })*/
    }
    if (!(typeof order?.deliveryRefs?.deliveryId === 'undefined')) {
      fetchDelivery({
        id: order?.deliveryRefs?.deliveryId
      });
    }
  }, [fetchDelivery, fetchInvoice, fetchPayment, order]);

  const routeChange = () => {
    navigate(-1);
  };

  const linkRef = useRef(null);

  return (
    <Container className='root' maxWidth={'xl'}>
      <a ref={linkRef} />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Link color='inherit' className={styles.link} onClick={routeChange}>
          Vissza
        </Link>
      </Breadcrumbs>
      <Container maxWidth={'xl'} style={{ margin: '0 0 2rem 0' }}>
        <OrderHistoryWidget order={order} handler={getDateString} />
        {loading ? (
          <LoadIndicator color={'var(--primary-light)'} height={800} />
        ) : (
          <>
            <Grid container spacing={3} style={{ margin: '0 0 2rem 0' }}>
              <Slide direction='up' in={true} timeout={1000} unmountOnExit>
                <Grid key={`order_details_card`} item xs={12} md={6} lg={6}>
                  <Paper className={styles.cardHolder} style={{ height: '100%' }}>
                    <DetailsCard
                      title={orderDetailsData.cardTitle}
                      rows={orderDetailsData.rows}
                      icon={orderDetailsData.icon}
                      tooltipText={orderDetailsData.tooltipText}
                      buttonText={orderDetailsData.buttonText}
                      buttonDisabled={false}
                      handler={orderDetailsData?.handler}
                    />
                    {(delivery?.status === 'SHIPPED' || order?.status === 'DONE') && <CheckIcon />}
                  </Paper>
                </Grid>
              </Slide>
              <Slide direction='up' in={true} timeout={1000} unmountOnExit>
                <Grid key={`payment_details_card`} item xs={12} md={6} lg={6}>
                  <Paper className={styles.cardHolder} style={{ height: '100%' }}>
                    <DetailsCard
                      title={paymentDetailsData.cardTitle}
                      rows={paymentDetailsData.rows}
                      icon={paymentDetailsData.icon}
                      tooltipText={paymentDetailsData.tooltipText}
                      buttonText={paymentDetailsData.buttonText}
                      buttonDisabled={paymentDetailsData.buttonDisabled}
                      handler={paymentDetailsData?.handler}
                    />
                    {(payment?.status === 'PAID' || order?.status === 'DONE') && <CheckIcon />}
                  </Paper>
                </Grid>
              </Slide>
              <Slide direction='up' in={true} timeout={1000} unmountOnExit>
                <Grid key={`shipping_details_card`} item xs={12} md={6} lg={6}>
                  <Paper className={styles.cardHolder} style={{ height: '100%' }}>
                    <DetailsCard
                      title={shippingDetailsData.cardTitle}
                      rows={shippingDetailsData.rows}
                      icon={shippingDetailsData.icon}
                      tooltipText={shippingDetailsData.tooltipText}
                      buttonText={shippingDetailsData.buttonText}
                      buttonDisabled={
                        !emailDeliveryFound ||
                        (user?.ownedShops[0]?.id !== shopId &&
                          !user?.maintainedShops
                            ?.find((shop) => shop.shop.id === shopId)
                            ?.scopes?.includes('MANAGE_ORDERS'))
                      }
                      handler={shippingDetailsData?.handler}
                    />
                    {(delivery?.status === 'SHIPPED' || order?.status === 'DONE') && <CheckIcon />}
                  </Paper>
                </Grid>
              </Slide>
              <Slide direction='up' in={true} timeout={1000} unmountOnExit>
                <Grid key={`billing_details_card`} item xs={12} md={6} lg={6}>
                  <Paper className={styles.cardHolder} style={{ height: '100%' }}>
                    <DetailsCard
                      title={billingDetailsData.cardTitle}
                      rows={billingDetailsData.rows}
                      icon={billingDetailsData.icon}
                      tooltipText={billingDetailsData.tooltipText}
                      buttonText={billingDetailsData.buttonText}
                      buttonDisabled={billingDetailsData.buttonDisabled}
                      handler={billingDetailsData?.handler}
                      downloadProgress={downloadProgress}
                    />
                    {/* {(invoice?.status === "PRINTED" ||
                      order?.status === "DONE") && <CheckIcon />} */}
                    <div className={styles.invoicesCard}>
                      <ul className={styles.listItemTable}>
                        {invoiceLoading ? (
                          <LoadIndicator color={'var(--primary-light)'} height={200} />
                        ) : invoice?.length > 0 ? (
                          invoice?.map((inv) => (
                            <li className={styles.listItem} width='100%' key={inv.id}>
                              <div className={styles.hideXSCell} width='25%'>
                                <Typography className={styles.tableCell}>
                                  <b>Számla {inv?.id}</b>
                                </Typography>
                              </div>
                              <div className={styles.showXSCell} width='35px'>
                                <Typography className={styles.tableCell}>
                                  <b>{inv?.id}</b>
                                </Typography>
                              </div>
                              <div>
                                {invoiceVendors && (
                                  <Typography className={styles.tableCell}>
                                    {invoiceVendors?.find((v) => v.id === inv.vendorId)
                                      ? invoiceVendors?.find((v) => v.id === inv.vendorId)?.vendorType === 'SZAMLAZZ_HU'
                                        ? 'Számlázz.hu'
                                        : 'Billingo'
                                      : '-'}
                                  </Typography>
                                )}
                              </div>
                              <div className={styles.hideCell} width='20%'>
                                <Typography className={styles.tableCell}>
                                  {inv?.status === 'PRINTED'
                                    ? 'KIÁLLíTVA'
                                    : inv?.status === 'REJECTED'
                                    ? 'ELUTASÍTVA'
                                    : inv?.status}
                                </Typography>
                              </div>
                              <div width='30px'>
                                {inv?.status === 'REJECTED' ? (
                                  <div className={styles.redDot}></div>
                                ) : (
                                  <CheckIcon position={'unset'} />
                                )}
                              </div>
                              <div width='50px'>
                                {!invoiceVendors?.find((v) => v.id === inv.vendorId) ? (
                                  <Tooltip
                                    title={'Kapcsolódó számlakibocsátó nem található, így a letöltés nem lehetséges'}>
                                    <IconButton onClick={() => handleGetPdf(inv?.id)} disabled={true}>
                                      <DownloadIcon></DownloadIcon>
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <IconButton
                                    onClick={() => handleGetPdf(inv?.id)}
                                    disabled={inv?.status === 'REJECTED'}>
                                    <DownloadIcon></DownloadIcon>
                                  </IconButton>
                                )}
                              </div>
                            </li>
                          ))
                        ) : (
                          ''
                        )}
                      </ul>
                    </div>
                  </Paper>
                </Grid>
              </Slide>
              {!shopTier?.automaticInvoice && !shopTier?.onlinePayment && (
                <Slide direction='up' in={true} timeout={1000} unmountOnExit>
                  <Grid key={`status_selector`} item xs={12}>
                    <Paper className={styles.cardHolder}>
                      <StatusModifier putOrder={putOrder} order={order} />
                    </Paper>
                  </Grid>
                </Slide>
              )}
              {order?.items && (
                <Slide direction='up' in={true} timeout={1000} unmountOnExit>
                  <Grid key={`order_list`} item xs={12}>
                    <Paper className={styles.orderListWrapper}>
                      <OrderList shopId={shopId} cartItems={order?.items} />
                    </Paper>
                  </Grid>
                </Slide>
              )}
            </Grid>
          </>
        )}
      </Container>
      <HistoryViewer
        data={order}
        historyVisible={orderHistoryVisible}
        toggleHistoryVisible={toggleOrderHistoryVisible}
      />
      <HistoryViewer
        data={payment}
        historyVisible={paymentHistoryVisible}
        toggleHistoryVisible={togglePaymentHistoryVisible}
      />

      <ConfirmDialog
        title={'Biztosan újra akarod nyomtatni a képeket?'}
        functionButtonText={'Igen'}
        closeButtonText={'Mégsem'}
        multipleButtons={true}
        functionButtonColor={'var(--primary-success)'}
        onClose={toogleReprintDialogOpen}
        open={reprintDialogOpen}
        onOk={() => {
          handleReprint(postDeliveryFound?.deliveryId);
          toogleReprintDialogOpen();
        }}
      />
      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}>
        <DialogTitle>Add meg az új kézbesítési címet!</DialogTitle>
        <TextField
          className={styles.newEmail}
          autoFocus
          type='email'
          label={'Új kézbesítési cím'}
          variant='outlined'
          onChange={(e) => {
            setNewEmail(e.target.value);
          }}
        />
        <Button
          color='primary'
          disabled={newEmail === ''}
          className={styles.resendConfirm}
          onClick={() => {
            resendDigital();
            setIsOpen(false);
          }}>
          Küldés
        </Button>
      </Dialog>
    </Container>
  );
};

export default OrderDetails;
