import { useEffect, useState } from 'react';
import styles from './Printing.module.css';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Checkbox, Tooltip } from '@material-ui/core';
import PrintIcon from '@mui/icons-material/Print';
import { useRef } from 'react';
import QueryBuilder from 'components/common/QueryBuilder/QueryBuilder';
import { usePrintableGroupLogic } from './hooks/usePrintableGroupLogic';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { useModifierApi } from 'pages/PhotoAdmin/AdminMenu/Templates/hooks/useModifierApi';
import { usePrinterApi } from 'pages/PrinterAdmin/hooks/usePrinterApi';
import { useSelector } from 'react-redux';
import { Grow } from '@mui/material';

const Printing = () => {
  const navigate = useNavigate();
  const { getModifiers, modifiers } = useModifierApi();
  const { search } = useLocation();

  const [showAll, setShowAll] = useState(false);
  const [isPrintingDoneAll, setIsPrintingDoneAll] = useState(false);
  const [isPrintedAll, setIsPrintedAll] = useState(false);

  const [isDeliveryType, setIsDeliveryType] = useState(false);

  const [open, setOpen] = useState();

  const [allSelected, setAllSelected] = useState(false);

  const { fetchGroups, printGroup, groups, setGroups, loading, groupsLength, setGroupsLength } =
    usePrintableGroupLogic();

  const { printGroupFinal } = usePrinterApi();

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  const toggleGroup = (groups, currentGroup) => {
    const newGroups = groups.map((group) => {
      if (group.id === currentGroup.id) {
        return { ...group, selected: !group.selected };
      }
      return group;
    });
    setGroups(newGroups);
  };
  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleSelectAll = (groups, e) => {
    setAllSelected(e.target.checked);
    const newGroups = groups.map((group) => ({
      ...group,
      selected: e.target.checked
    }));
    setGroups(newGroups);
  };

  const handlePrint = async () => {
    let promises = [];
    groups?.forEach(async (group) => {
      if (group.selected) {
        let newGroup = { ...group };
        delete newGroup.selected;
        let promise = printGroup({
          group: { ...newGroup, isPrinted: true }
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    let printer = '';
    if (search.includes('isActive=ISPRINTED')) {
      printer = search.replace('isActive=ISPRINTED', 'isPrinted=true&isPrintingDone=false');
    } else if (search.includes('isActive=ISPRINTINGDONE')) {
      printer = search.replace('isActive=ISPRINTINGDONE', 'isPrintingDone=true');
    } else if (search.includes('isActive=ISNOTPRINTED')) {
      printer = search.replace('isActive=ISNOTPRINTED', 'isPrinted=false');
    }
    await fetchGroups({ queryString: printer === '' ? search : printer });
  };
  const handlePrintEnding = async () => {
    let promises = [];
    groups?.forEach(async (group) => {
      if (group.selected) {
        let newBody = { ...group };
        delete newBody.selected;
        newBody.isPrintingDone = true;
        let promise = printGroupFinal({
          group: { ...newBody }
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    refresh();
  };

  const refresh = () => {
    let printer = '';
    if (search.includes('isActive=ISPRINTED')) {
      printer = search.replace('isActive=ISPRINTED', 'isPrinted=true&isPrintingDone=false');
    } else if (search.includes('isActive=ISPRINTINGDONE')) {
      printer = search.replace('isActive=ISPRINTINGDONE', 'isPrintingDone=true');
    } else if (search.includes('isActive=ISNOTPRINTED')) {
      printer = search.replace('isActive=ISNOTPRINTED', 'isPrinted=false');
    }
    fetchGroups({ queryString: printer === '' ? search : printer });
  };

  const checkSelfPrint = () => {
    let isSelf = false;
    modifiers?.forEach((el) => {
      if (el?.deliveryTypeDTO?.includes('LELKES')) {
        isSelf = true;
      }
    });
    setIsDeliveryType(isSelf);
  };

  const linkRef = useRef(null);

  // Add dummy data for disabled printing
  const dummyGroups = [
    {
      id: 'DEMO-001',
      name: 'Demo Általános Iskola',
      createdAt: new Date().toISOString(),
      isActive: true,
      isPrinted: false,
      isPrintingDone: false,
      selected: false
    },
    {
      id: 'DEMO-002',
      name: 'Minta Gimnázium',
      createdAt: new Date().toISOString(),
      isActive: true,
      isPrinted: true,
      isPrintingDone: false,
      selected: false
    },
    {
      id: 'DEMO-003',
      name: 'Példa Szakközépiskola',
      createdAt: new Date().toISOString(),
      isActive: true,
      isPrinted: true,
      isPrintingDone: true,
      selected: false
    }
  ];

  useEffect(() => {
    if (shopTier?.printOption) {
      let printer = '';
      if (search.includes('isActive=ISPRINTED')) {
        printer = search.replace('isActive=ISPRINTED', 'isPrinted=true&isPrintingDone=false');
      } else if (search.includes('isActive=ISPRINTINGDONE')) {
        printer = search.replace('isActive=ISPRINTINGDONE', 'isPrintingDone=true');
      } else if (search.includes('isActive=ISNOTPRINTED')) {
        printer = search.replace('isActive=ISNOTPRINTED', 'isPrinted=false');
      }
      fetchGroups({ queryString: printer === '' ? search : printer });
    } else {
      setGroups(dummyGroups);
      setGroupsLength(dummyGroups.length);
    }
  }, [search, shopTier?.printOption]);

  // Update loading state for dummy data
  const isLoading = shopTier?.printOption ? loading : false;

  useEffect(() => {
    getModifiers();
    refresh();
  }, []);

  useEffect(() => {
    checkSelfPrint();
  }, [modifiers]);

  useEffect(() => {
    setIsPrintingDoneAll(groups?.find((el) => el.isPrintingDone === true) ? true : false);
    setIsPrintedAll(groups?.find((el) => el.isPrinted === true) ? true : false);
  }, [groups]);

  const path = useLocation().pathname;

  const resetQueryString = () => {
    navigate(path);
  };
  return (
    <Container maxWidth={false} className={styles.root}>
      <CssBaseline />
      <a ref={linkRef} />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography style={{ fontFamily: 'var(--font-main)' }} variant='body1'>
          Nyomtatás
        </Typography>
      </Breadcrumbs>
      <Grow in={true}>
        <Container maxWidth={false} className={styles.container}>
          <QueryBuilder
            itemsLength={groupsLength}
            siteType={'admin'}
            showAll={showAll}
            reset={resetQueryString}
            refresh={refresh}
            disabled={!shopTier?.printOption}>
            <table className={styles.headerTop}>
              <tbody>
                <tr width='100%' className={styles.searchBar}>
                  <td
                    // className={classes.hideXs}
                    style={{
                      color: 'whitesmoke',
                      textAlign: 'center',
                      display: 'flex',
                      gap: '1rem'
                    }}>
                    <Tooltip title={'Kijelölt iskolák és azok által tartalmazott galériák küldése nyomtatásra'}>
                      <Button
                        variant='outlined'
                        style={{
                          backgroundColor:
                            groups?.find((group) => group.selected) && 'var(--primary-background-content)',
                          color: 'var(--primary-background-primary)',
                          fontFamily: 'var(--font-main) !important',
                          marginTop: 0,
                          border: `2px solid var(--primary-background-primary)`,
                          transition: 'background 300ms'
                          // opacity: !deliveries?.find(
                          //   (delivery) => delivery.selected
                          // )
                          //   ? 0.3
                          //   : 1,
                        }}
                        disabled={
                          (!groups?.find((group) => group.selected) &&
                            isPrintedAll &&
                            !isPrintingDoneAll &&
                            isPrintedAll &&
                            isPrintingDoneAll) ||
                          !shopTier?.printOption
                        }
                        onClick={() => setOpen('printConfirm')}>
                        <PrintIcon />
                        Küldés nyomtatásra
                      </Button>
                    </Tooltip>
                    {isDeliveryType ? (
                      <Tooltip title={'Kijelölt iskolák és azok által tartalmazott galériák küldése nyomtatásra'}>
                        <Button
                          variant='outlined'
                          style={{
                            backgroundColor:
                              groups?.find((group) => group.selected) && 'var(--primary-background-content)',
                            color: 'var(--primary-background-primary)',
                            margin: 0,
                            border: `2px solid var(--primary-background-primary)`,
                            fontFamily: 'var(--font-main) !important',
                            transition: 'background 300ms'
                          }}
                          disabled={
                            (!groups?.find((group) => group.selected) &&
                              !isPrintedAll &&
                              !isPrintingDoneAll &&
                              isPrintedAll &&
                              isPrintingDoneAll) ||
                            !shopTier?.printOption
                          }
                          onClick={() => setOpen('printEnding')}>
                          <PrintIcon />
                          Nyomtatás véglegesítése
                        </Button>
                      </Tooltip>
                    ) : (
                      ''
                    )}
                    <ConfirmDialog
                      open={open === 'printConfirm'}
                      title='Biztosan nyomtatóra akarod küldeni a kijelölt iskolákat? A kijelölt iskolákban lévő összes galéria nyomtatásra kerül, azokból vásárolni már csupán digitális formátumú képeket lehet és a hozzájuk tartozó nyomtatandó rendelések beérkeznek hozzánk. Ezt követően, minél hamarabb megkezdjük ezek nyomtatását.'
                      functionButtonText='Nyomtatás'
                      functionButtonColor='var(--primary-success)'
                      demo={!!user?.ownedShops?.find((shop) => shop.id == shopId)?.isDemo}
                      onOk={async () => {
                        setOpen(null);
                        await handlePrint();
                        setAllSelected(false);
                      }}
                      onClose={() => setOpen(null)}
                      closeButtonText='Mégsem'
                      multipleButtons
                    />
                    <ConfirmDialog
                      open={open === 'printEnding'}
                      title='Biztosan Véglegesíted a nyomtatást? Ezáltal a fotósoknak is visszajelzést küldünk, hogy a kijelölt iskolák nyomtatása megtörtént!'
                      functionButtonText='Nyomtatás véglegesítése'
                      functionButtonColor='var(--primary-success)'
                      onOk={async () => {
                        setOpen(null);
                        await handlePrintEnding();
                        setAllSelected(false);
                      }}
                      onClose={() => setOpen(null)}
                      closeButtonText='Mégsem'
                      multipleButtons
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className={styles.header}>
              <thead>
                <tr width='100%'>
                  <td className={styles.heading} width='10%'>
                    <Checkbox
                      style={{ color: 'whitesmoke' }}
                      checked={allSelected}
                      onChange={(e) => handleSelectAll(groups, e)}
                      disabled={!shopTier?.printOption}
                    />
                  </td>
                  <td className={styles.hideXs} style={{ width: '5%' }}>
                    <Typography className={styles.heading}>#</Typography>
                  </td>
                  <td className={styles.hideXs} style={{ width: '10%' }}>
                    <Typography className={styles.heading}>Azonosító</Typography>
                  </td>
                  <td className={styles.heading}>
                    <Typography className={styles.heading}>Iskola neve</Typography>
                  </td>
                  <td className={styles.heading}>
                    <Typography className={styles.heading}>Létrehozás dátuma</Typography>
                  </td>
                  <td className={styles.hideXs}>
                    <Typography className={styles.heading}>Státusz</Typography>
                  </td>
                  <td className={styles.tableCell}>
                    <Typography className={styles.heading}>Nyomtatás állapota</Typography>
                  </td>
                </tr>
              </thead>
            </table>
            <div className={styles.orderListContainer}>
              <table className={styles.listitemtable}>
                <tbody>
                  {isLoading ? (
                    <tr>
                      <td>
                        <LoadIndicator color={'var(--primary-light)'} height={800} />
                      </td>
                    </tr>
                  ) : groups?.length > 0 ? (
                    groups?.map((group, idx) => (
                      <tr
                        className={shopTier?.printOption ? styles.listItem : styles.listItemDisabled}
                        width='100%'
                        key={group.id}
                        onClick={() => {
                          if (shopTier?.printOption) {
                            toggleGroup(groups, group);
                          }
                        }}>
                        <td className={styles.tableCell} width='10%'>
                          <Checkbox
                            color='primary'
                            checked={group?.selected}
                            onChange={(e) => toggleGroup(groups, group)}
                            disabled={!shopTier?.printOption}
                          />
                        </td>
                        <td className={styles.hideXs} style={{ width: '5%' }}>
                          <Typography className={styles.tableCell}>{idx + 1}</Typography>
                        </td>
                        <td className={styles.hideXs} style={{ width: '10%' }}>
                          <Typography className={styles.tableCell}>{group?.id}</Typography>
                        </td>
                        <td className={styles.tableCell}>
                          <Typography className={styles.tableCell}>{group?.name}</Typography>
                        </td>
                        <td className={styles.tableCell}>
                          {group?.createdAt && (
                            <Typography className={styles.tableCell}>
                              {new Date(group?.createdAt).toLocaleDateString('hu-HU', {
                                hour: '2-digit',
                                minute: '2-digit',
                                seconds: '2-digit'
                              })}
                            </Typography>
                          )}
                        </td>

                        <td className={styles.hideXs}>
                          <Typography
                            style={{
                              fontFamily: 'var(--font-main)',
                              color: group?.isActive ? 'rgb(25,109,235)' : 'var(--primary-error)'
                            }}>
                            {group?.isActive ? 'Aktív' : 'Inaktív'}
                          </Typography>
                        </td>
                        <td className={styles.tableCell}>
                          <Typography
                            style={{
                              fontFamily: 'var(--font-main)',
                              color: !group?.isPrinted
                                ? 'black'
                                : group?.isPrinted && !group?.isPrintingDone
                                ? 'rgb(204, 204, 0 )'
                                : 'var(--primary-success)',
                              fontWeight: group?.isPrinted && group?.isPrintingDone && 'bold'
                            }}>
                            {!group?.isPrinted
                              ? 'Nyomtatható'
                              : group?.isPrinted && !group?.isPrintingDone
                              ? 'Nyomtatásra vár'
                              : 'Nyomtatva'}
                          </Typography>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr
                      style={{
                        margin: '0.5rem',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                      }}>
                      <td>
                        <Typography variant='h5' style={{ color: 'var(--primary-light)' }}>
                          {shopTier?.printOption
                            ? 'Nincs nyomtatható galéria.'
                            : 'A nyomtatás funkció nem elérhető az Ön előfizetési csomagjában.'}
                        </Typography>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </QueryBuilder>

          <button
            className={styles.allBtn}
            onClick={() => {
              handleShowAll();
            }}>
            {showAll ? 'Kevesebb nyomtatandó galéria' : 'Összes nyomtatandó galéria'}
          </button>
        </Container>
      </Grow>
    </Container>
  );
};

export default Printing;
