import { getApi } from 'api/axios';
import { errorToast, successToast } from 'components/common/Toast/Toast';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export const useTemplateApi = () => {
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const [templates, setTemplates] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);

  const [deleteProgress, setDeleteProgress] = useState(false);
  const [updateProgress, setUpdateProgress] = useState(false);
  const [createProgress, setCreateProgress] = useState(false);
  const [templatesError, setTemplatesError] = useState(null);

  const getTemplates = async () => {
    try {
      setTemplatesLoading(true);

      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/shops/${shopId}/modifiers/templates`);
      setTemplates(res.data);
      setTemplatesLoading(false);
    } catch (error) {
      setTemplatesLoading(false);
      setTemplatesError(error);
    }
  };
  const [template, setTemplate] = useState(null);
  const [templateLoading, setTemplateLoading] = useState(false);
  const [templateError, setTemplateError] = useState(null);

  const getTemplate = async (id) => {
    try {
      setTemplateLoading(true);

      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).get(`/admin/shops/${shopId}/modifiers/templates/${id}`);
      setTemplate(res.data);
      setTemplateLoading(false);
    } catch (error) {
      setTemplateLoading(false);
      setTemplateError(error);
    }
  };

  // todo useState for states

  const postTemplate = async (body) => {
    try {
      setTemplatesLoading(true);
      setCreateProgress(true);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/admin/shops/${shopId}/modifiers/templates`, body);
      //setTemplates(res.data);
      setTemplatesLoading(false);
      setCreateProgress(false);
      successToast('Sikeres létrehozás!');
    } catch (error) {
      setTemplatesLoading(false);
      setCreateProgress(false);
      setTemplatesError(error);
      errorToast('Sikertelen létrehozás!');
    }
  };

  const putTemplate = async (body) => {
    try {
      setTemplatesLoading(true);
      setUpdateProgress(true);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).put(`/admin/shops/${shopId}/modifiers/templates`, body);
      //setTemplates(res.data);
      setTemplatesLoading(false);
      setUpdateProgress(false);
      successToast('Sikeres frissítés!');
    } catch (error) {
      setTemplatesLoading(false);
      setUpdateProgress(false);
      setTemplatesError(error);
      errorToast('Sikertelen frissítés!');
    }
  };

  const deleteTemplate = async (id) => {
    try {
      setTemplatesLoading(true);
      setDeleteProgress(true);
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).delete(`/admin/shops/${shopId}/modifiers/templates/${id}`);
      //setTemplates(res.data);
      setTemplatesLoading(false);
      setDeleteProgress(false);
      successToast('Sikeres törlés!');
    } catch (error) {
      setTemplatesLoading(false);
      setDeleteProgress(false);
      setTemplatesError(error);
      errorToast('Sikertelen törlés!');
    }
  };

  const getTemplateBody = (id, name, currentModifiers) => ({
    id: id || null,
    name: name,
    //shopId: 1,
    //creatorId: 'dev',
    technical: false,
    modifiers: currentModifiers
  });

  return {
    templates,
    templatesLoading,
    templatesError,
    template,
    templateLoading,
    templateError,
    getTemplates,
    getTemplate,
    setTemplate,
    postTemplate,
    putTemplate,
    deleteTemplate,
    getTemplateBody,
    deleteProgress,
    updateProgress,
    createProgress
  };
};
