import React, { useEffect, useState } from 'react';
import logoSvg from 'assets/images/logo.svg';
import styles from './BundleCard.module.css';
import { MdCheck, MdClose, MdInfo } from 'react-icons/md';
import SubscriptionDialog from '../SubscriptionDialog/SubscriptionDialog';
import { Tooltip } from '@mui/material';

const BundleCard = ({ bundleData, currentTier, assign, unassign, isAnnual }) => {
  const [open, setOpen] = useState();

  useEffect(() => {
    console.log(bundleData);
  }, [bundleData]);

  return (
    <div className={styles.outsideCard}>
      <div
        className={`${currentTier?.id !== bundleData?.id ? styles.mainContainer : styles.mainContainerOutlined} ${
          styles.fadeIn
        }`}>
        {currentTier?.id === bundleData?.id && <div className={styles.current_banner}>Aktuális előfizetés</div>}
        <div className={styles.innerPart}>
          <div className={styles.logoContainer}>
            <img src={logoSvg} alt='logo' />
          </div>
          <h2>{bundleData?.name}</h2>
          <div className={styles.infoSection}>
            <div className={styles.info_item}>
              Galéria létrehozás: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Képfeltöltés: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Saját termék definiálás: <MdCheck color='green' size={20} />
            </div>
            <div className={styles.info_item}>
              Rendelési határidő beállítás:{' '}
              {bundleData?.galleryExpiryDate ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Online fizetés:{' '}
              {bundleData?.onlinePayment ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Árazási sablon létrehozás:{' '}
              {bundleData?.pricingTemplates ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Automata számlázás:{' '}
              {bundleData?.automaticInvoice ? <MdCheck color='green' size={20} /> : <MdClose color='red' size={20} />}
            </div>
            <div className={styles.info_item}>
              Galéria limit: <span>{`${bundleData?.galleryLimit} db`}</span>
            </div>
            <div className={styles.info_item}>
              Képfeltöltési limit: <span>{`${bundleData?.productsLimit} db`}</span>
            </div>
          </div>
          {bundleData.recurringPrice > 0 ? (
            isAnnual ? (
              <div className={styles.payment}>{bundleData.recurringPrice * 10} Ft/év</div>
            ) : (
              <div className={styles.payment}>{bundleData.recurringPrice} Ft/hó</div>
            )
          ) : (
            <Tooltip
              title={`Prémium csomag esetén elengedjük a szokásos havidíjakat. Helyette egy jutalékos rendszer lép életbe, ahol csak az értékesítéseid után fizetsz, az értékesített összeg ${
                bundleData.commissionRate * 100
              }%-át. További részleteket az Általános Szerződési Feltételek között találhatsz erről.`}>
              <div className={styles.payment}>
                Részesedés: {bundleData.commissionRate * 100} % <MdInfo size={20} />
              </div>
            </Tooltip>
          )}

          <button className={styles.introButton} onClick={() => setOpen(true)}>
            {'Előfizetés kiválasztása'}
          </button>
        </div>
      </div>
      <SubscriptionDialog open={open} setOpen={setOpen} bundleData={bundleData} assign={assign} unassign={unassign} isAnnual={isAnnual} />
    </div>
  );
};

export default BundleCard;
