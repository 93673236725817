import { MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import styles from './SelectEditor.module.css';

const SelectEditor = ({ option, setOption }) => {
  const [currentOption, setCurrentOption] = useState(option.value === '' ? -1 : option.value.split('=')[1]);

  const selectProps = {
    required: true,
    variant: 'outlined',
    fullWidth: true,
    className: styles.select
  };

  return (
    <div className={styles.mainContainer}>
      {option.values.length > 0 ? (
        <Select
          {...selectProps}
          onChange={(e) => {
            setOption({ ...option, value: `${option.name}=${e.target.value}` });
            setCurrentOption(e.target.value);
          }}
          value={currentOption}>
          <MenuItem key={`default_template_option`} value={-1} disabled>
            Kérem válasszon...
          </MenuItem>
          {option.values?.map((value, idx) => (
            <MenuItem key={`${option.name}_option_${value}`} value={value}>
              {option.displayedValues[idx]}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...selectProps} defaultValue={-1}>
          <MenuItem key={`no_items`} value={-1} disabled>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </div>
  );
};

export default SelectEditor;
