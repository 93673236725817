import React from 'react';
import styles from './TemplateMain.module.css';
import ProductTypes from '../ProductTypes/ProductTypes';
import TemplateBubbles from '../TemplateBubbles/TemplateBubbles';
import ProductTypeEditor from '../ProductTypeEditor/ProductTypeEditor';
import { useSelector } from 'react-redux';
import { useProductTypesLogic } from '../ProductTypes/hooks/useProductTypesLogic';

const TemplateMain = ({ isTemplate, setIsTemplate }) => {
  const {
    selectedEdit,
    isEdit,
    datagridData,
    handleProductTypeSelect,
    handleAddNew,
    currentModifiers,
    resetForm,
    handleDialogSubmit,
    handleDialogClose,
    dialogOpen,
    setDialogOpen,
    handleSubmit,
    deleteModifier,
    createPrio2Modifier,
    createModifier,
    updateModifier,
    updatePrio2Modifier,
    prio2Edit,
    setPrio2Edit,
    setSelectedEdit,
    setIsEdit
  } = useProductTypesLogic();

  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  return !isEdit && !isTemplate ? (
    <div className={styles.mainPart}>
      <ProductTypes
        handleProductTypeSelect={handleProductTypeSelect}
        handleAddNew={handleAddNew}
        currentModifiers={currentModifiers?.data}
      />
      {shopTier?.pricingTemplates ? (
        <TemplateBubbles setIsTemplate={setIsTemplate} />
      ) : (
        <div className={styles.overlayContainer}>
          <div className={styles.overlay}>
            <div className={styles.overlayText}>
              Árazási sablon létrehozását előfizetésed nem támogatja. Javasoljuk hogy válts nagyobb csomagra!
            </div>
          </div>
          <div className={styles.content}>
            <TemplateBubbles setIsTemplate={setIsTemplate} isOverlayed={true} />
          </div>
        </div>
      )}
    </div>
  ) : !isTemplate ? (
    <div className={styles.mainPart}>
      <ProductTypeEditor
        selectedEdit={selectedEdit}
        setIsEdit={setIsEdit}
        setSelectedEdit={setSelectedEdit}
        deleteModifier={deleteModifier}
        createModifier={createModifier}
        updateModifier={updateModifier}
        createPrio2Modifier={createPrio2Modifier}
        updatePrio2Modifier={updatePrio2Modifier}
        edit={prio2Edit}
        setEdit={setPrio2Edit}
        handleSubmit={handleSubmit}
        onClose={resetForm}
        datagridData={datagridData}
        handleDialogSubmit={handleDialogSubmit}
        handleDialogClose={handleDialogClose}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
      />
    </div>
  ) : null;
};

export default TemplateMain;
