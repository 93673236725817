import { MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './KeyValueEditor.module.css';

const KeyValueEditor = ({ option, setOption }) => {
  const [currentKey, setCurrentKey] = useState(option.value === '' ? -1 : option.value.split('=')[1].split(',')[0]);
  const [currentValue, setCurrentValue] = useState(option.value === '' ? -1 : option.value.split('=')[1].split(',')[1]);

  useEffect(() => {
    if (currentKey !== -1 && currentValue !== -1) {
      setOption({
        ...option,
        value: `${option.name}=${currentKey},${currentValue}`
      });
    }
  }, [currentKey, currentValue]);

  const selectProps = {
    required: true,
    variant: 'outlined',
    fullWidth: true,
    className: styles.select
  };

  return (
    <div className={styles.mainContainer}>
      {option.keys.length > 0 ? (
        <Select {...selectProps} onChange={(e) => setCurrentKey(e.target.value)} value={currentKey}>
          <MenuItem key={`default_template_option_key`} value={-1} disabled>
            Kérem válasszon...
          </MenuItem>
          {option.keys?.map((key) => (
            <MenuItem key={`${option.name}_option_key_${key.name}`} value={key.name}>
              {key.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...selectProps} defaultValue={-1}>
          <MenuItem key={`no_items`} value={-1} disabled>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
      {option.values.length > 0 ? (
        <Select {...selectProps} onChange={(e) => setCurrentValue(e.target.value)} value={currentValue}>
          <MenuItem key={`default_template_option`} value={-1} disabled>
            Kérem válasszon...
          </MenuItem>
          {option.values?.map((value) => (
            <MenuItem key={`${option.name}_option_${value.name}`} value={value.name}>
              {value.displayName}
            </MenuItem>
          ))}
        </Select>
      ) : (
        <Select {...selectProps} defaultValue={-1}>
          <MenuItem key={`no_items`} value={-1} disabled>
            Nincs megjeleníthető elem
          </MenuItem>
        </Select>
      )}
    </div>
  );
};

export default KeyValueEditor;
