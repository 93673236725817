/** @format */

import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import ChartQueryBuilder from 'components/common/ChartQueryBuilder/ChartQueryBuilder';
import { useReportChartLogics } from './useReportChartLogics';
import { MenuItem, Select } from '@mui/material';

const ReportChart = ({ url, chartName, siteType, dataType, modifiers, charts, disabled = false }) => {
  const [selectedChart, setSelectedChart] = useState(charts[0].value);
  const { fetchOrderedItems, orderedData, stateB, stateC } = useReportChartLogics(
    url,
    selectedChart,
    chartName,
    dataType,
    modifiers
  );

  // Add dummy data for disabled state
  const dummyData = {
    bar: {
      options: {
        chart: { type: 'bar' },
        xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May'] }
      },
      series: [
        {
          name: 'Sample Data',
          data: [30, 45, 25, 60, 35]
        }
      ]
    },
    pie: {
      options: {
        labels: ['Category A', 'Category B', 'Category C']
      },
      series: [44, 55, 33]
    },
    donut: {
      options: {
        labels: ['Category A', 'Category B', 'Category C']
      },
      series: [44, 55, 33]
    }
  };

  useEffect(() => {
    if (!disabled) {
      fetchOrderedItems();
    }
  }, []);

  const getChartData = () => {
    if (disabled) {
      return dummyData[selectedChart];
    }
    return dataType === 'ordered' && selectedChart === 'bar' ? stateC : stateB;
  };

  const chartData = getChartData();

  return (
    <div>
      <ChartQueryBuilder
        chartName={chartName}
        siteType={siteType}
        disabled={!orderedData || disabled}
        accountingData={disabled ? [] : orderedData}
        refetch={fetchOrderedItems}>
        <Select
          id='language'
          name='language'
          value={selectedChart}
          onChange={(e) => {
            setSelectedChart(e.target.value);
          }}>
          {charts.map((c) => (
            <MenuItem key={c.value} value={c.value}>
              {c.displayName}
            </MenuItem>
          ))}
        </Select>
        <div style={{ marginTop: '1.5rem' }}>
          <ReactApexChart
            disabled={disabled}
            key={selectedChart}
            options={chartData?.options}
            series={chartData?.series}
            type={selectedChart}
            height={selectedChart === 'pie' || selectedChart === 'donut' ? 363 : 350}
          />
        </div>
      </ChartQueryBuilder>
    </div>
  );
};

export default ReportChart;
