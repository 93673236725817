import { useSelector } from 'react-redux';
import queries from '../../../../api/queries/SubscriptionQueries';
import paymentQueries from '../../../../api/queries/PaymentQueries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { errorToast } from 'components/common/Toast/Toast';

export const useSubscriptionLogic = () => {
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);
  const queryClient = useQueryClient();

  const fetchPublicTiersQuery = useQuery({
    queryKey: ['publicTiers'],
    queryFn: () => queries.getPublicShopTiers(),
    retry: (failureCount, error) => (error.response.status === 404 ? false : true),
    refetchOnWindowFocus: false
  });

  const fetchCardRegister = useQuery({
    queryKey: ['cardRegistration'],
    queryFn: () => paymentQueries.getRegistration({ shopId: shopId }),
    onSuccess: (data) => {
      console.log(data);
    },
    refetchOnWindowFocus: false,
    enabled: user?.ownedShops?.length > 0
  });

  const assignTier = useMutation({
    mutationFn: ({ tierId, isAnnual }) => {
      queries.assignShopTier({ shopId: shopId, tierId: tierId, isYearlyPricing: isAnnual });
    },
    onSuccess: () => queryClient.invalidateQueries([shopId, 'shopTier']),
    onError: () => errorToast('Előfizetés aktiválása sikertelen! Kérjük próbáld újra később!')
  });
  const unassignTier = useMutation({
    mutationFn: () => queries.unassignShopTier({ shopId: shopId, tierId: shopTier?.id }),
    onSuccess: () => queryClient.invalidateQueries([shopId, 'shopTier']),
    onError: () => errorToast('Előfizetés lemondása sikertelen! Kérjük próbáld újra később!')
  });

  return {
    shopTier,
    fetchPublicTiersQuery,
    assignTier,
    unassignTier,
    shopId,
    fetchCardRegister
  };
};
