/** @format */

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import styles from './PhotoReports.module.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Typography } from '@material-ui/core';
import ReportChart from 'components/SuperAdminComponents/Dashboard/ReportChart/ReportChart';
import { useSelector } from 'react-redux';
import { useModifierApi } from '../Templates/hooks/useModifierApi';
import { useEffect } from 'react';
import { Grow } from '@mui/material';

const PhotoReports = () => {
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);
  const accountingCharts = [
    { value: 'bar', displayName: 'Oszlopdiagram' },
    { value: 'line', displayName: 'Vonaldiagram' }
  ];
  const orderItemCharts = [
    { value: 'bar', displayName: 'Oszlopdiagram' },
    { value: 'pie', displayName: 'Kördiagram' },
    { value: 'donut', displayName: 'Fánkdiagram' }
  ];

  const { modifiers, getModifiers } = useModifierApi();

  useEffect(() => {
    getModifiers();
  }, []);

  return (
    <div className={styles.root}>
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography style={{ fontFamily: 'var(--font-main)' }} variant='body1'>
          Kimutatások
        </Typography>
      </Breadcrumbs>
      <CssBaseline />
      <main className={styles.content}>
        <Grow in={true}>
          <Container maxWidth={'xl'} className={styles.container}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {/* <Paper className={classes.paper}>
                  <Reports />
                </Paper> */}
              </Grid>
              <Grid item xs={12} lg={6}>
                <ReportChart
                  url={`/admin/shops/${shopId}/orders/accounting`}
                  siteType={'user'}
                  chartName={'accountingPhotoReport'}
                  dataType={'unordered'}
                  charts={accountingCharts}
                  disabled={!shopTier?.reports}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <ReportChart
                  url={`/admin/shops/${shopId}/orders/order-items/accounting`}
                  siteType={'user'}
                  chartName={'orderItemPhotoReport'}
                  dataType={'ordered'}
                  modifiers={modifiers}
                  charts={orderItemCharts}
                  disabled={!shopTier?.reports}
                />
              </Grid>
            </Grid>
          </Container>
        </Grow>
      </main>
    </div>
  );
};

export default PhotoReports;
