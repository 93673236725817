import React from 'react';
import { Button, CircularProgress, Grid, Tooltip } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AddIcon from '@mui/icons-material/Add';
import styles from './ActionButtons.module.css';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';

const ActionButtons = ({
  selectedTemplate,
  formChanged,
  disabled,
  currentModifiers,
  onDelete,
  onUpdate,
  onCreate,
  openUpdateDialog,
  openCreateDialog,
  openDeleteDialog,
  setOpenUpdateDialog,
  setOpenCreateDialog,
  setOpenDeleteDialog,
  handleDelete,
  handlePut,
  handlePost,
  progress
}) => (
  <Grid container className={styles.buttons}>
    <ConfirmDialog
      open={openDeleteDialog}
      onOk={() => {
        handleDelete();
        setOpenDeleteDialog(false);
      }}
      onClose={() => setOpenDeleteDialog(false)}
      title={'Biztosan törlöd a sablont?'}
      functionButtonText={'Törlés'}
      functionButtonColor={'var(--primary-error)'}
      closeButtonText={'Mégsem'}
      multipleButtons={true}
    />
    <Grid item xs={4} className={styles.button}>
      <Tooltip title='Sablon törlése'>
        <div>
          <Button
            style={{
              background: selectedTemplate && 'var(--primary-error)'
            }}
            variant='outlined'
            disabled={!selectedTemplate}
            onClick={onDelete}>
            {progress.delete ? (
              <CircularProgress
                size={24}
                style={{
                  color: 'var(--primary-text-primary)'
                }}
              />
            ) : (
              <DeleteIcon
                style={{
                  color: selectedTemplate && 'var(--primary-text-primary)'
                }}
              />
            )}
          </Button>
        </div>
      </Tooltip>
    </Grid>
    <ConfirmDialog
      open={openUpdateDialog}
      onOk={() => {
        handlePut();
        setOpenUpdateDialog(false);
      }}
      onClose={() => setOpenUpdateDialog(false)}
      title={'Biztosan frissíteni akarod?'}
      functionButtonText={'Frissítés'}
      functionButtonColor={'var(--primary-info)'}
      closeButtonText={'Mégsem'}
      multipleButtons={true}
    />
    <Grid item xs={4} className={styles.button}>
      <Tooltip title='Sablon frissítése'>
        <div>
          <Button
            style={{
              background: selectedTemplate && formChanged && !disabled && 'var(--primary-info)'
            }}
            variant='outlined'
            disabled={disabled || !selectedTemplate || !formChanged}
            onClick={onUpdate}>
            {progress.update ? (
              <CircularProgress
                size={24}
                style={{
                  color: 'var(--primary-text-primary)'
                }}
              />
            ) : (
              <AutorenewIcon
                style={{
                  color: selectedTemplate && formChanged && !disabled && 'var(--primary-text-primary)'
                }}
              />
            )}
          </Button>
        </div>
      </Tooltip>
    </Grid>
    <ConfirmDialog
      open={openCreateDialog}
      onOk={() => {
        handlePost();
        setOpenCreateDialog(false);
      }}
      onClose={() => setOpenCreateDialog(false)}
      title={'Elkészülhet az új sablonod?'}
      functionButtonText={'Létrehozás'}
      functionButtonColor={'var(--primary-success)'}
      closeButtonText={'Mégsem'}
      multipleButtons={true}
    />
    <Grid item xs={4} className={styles.button}>
      <Tooltip title='Új sablon létrehozása a megadott adatokkal'>
        <div>
          <Button
            variant='outlined'
            style={{
              background:
                !currentModifiers?.length > 0 || (selectedTemplate && !formChanged) || disabled
                  ? ''
                  : 'var(--primary-success)'
            }}
            disabled={disabled || !currentModifiers?.length > 0 || (selectedTemplate && !formChanged)}
            onClick={onCreate}>
            {progress.create ? (
              <CircularProgress
                size={24}
                style={{
                  color: 'var(--primary-text-primary)'
                }}
              />
            ) : (
              <AddIcon
                style={{
                  color:
                    !currentModifiers?.length > 0 || (selectedTemplate && !formChanged) || disabled
                      ? ''
                      : 'var(--primary-text-primary)'
                }}
              />
            )}
          </Button>
        </div>
      </Tooltip>
    </Grid>
  </Grid>
);
export default ActionButtons;
