import { Button, CircularProgress, MenuItem, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { MdAdd, MdClose, MdDelete, MdEdit } from 'react-icons/md';
import styles from './ProductTypeEditor.module.css';
import { Formik } from 'formik';
import Divider from 'components/common/Divider/Divider';
import { DataGrid } from '@mui/x-data-grid';
import ProductTypeDialog from '../ProductTypeDialog/ProductTypeDialog';
import { FormControl, Grow, InputLabel, Select, IconButton, Tooltip } from '@mui/material';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';

const ProductTypeEditor = ({
  selectedEdit,
  onClose,
  datagridData,
  handleDialogSubmit,
  handleDialogClose,
  dialogOpen,
  setDialogOpen,
  handleSubmit,
  edit,
  setEdit,
  deleteModifier,
  createModifier,
  updateModifier,
  createPrio2Modifier,
  updatePrio2Modifier,
  setSelectedEdit,
  setIsEdit
}) => {
  const [sureOpen, setSureOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(true);
  const columns = [
    { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'Név',
      flex: 2,
      editable: false
    },
    {
      field: 'description',
      headerName: 'Leírás',
      flex: 4,
      editable: false
    },
    {
      field: 'category',
      headerName: 'Címke',
      flex: 2,
      editable: false
    },
    {
      field: 'actions',
      headerName: 'Műveletek',
      flex: 1,
      minWidth: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <IconButton
              onClick={() => {
                setEdit(params.row);
                setDialogOpen(true);
              }}
              disabled={selectedEdit?.deliveryTypeDTO !== 'LELKES_SELF_PRINT' && !!selectedEdit}>
              <MdEdit
                color={
                  selectedEdit?.deliveryTypeDTO === 'LELKES_SELF_PRINT'
                    ? 'var(--primary-background-secondary)'
                    : 'disabled'
                }
              />
            </IconButton>
            <IconButton
              onClick={() => {
                setSureOpen(params.row.id);
              }}
              disabled={selectedEdit?.deliveryTypeDTO !== 'LELKES_SELF_PRINT' && !!selectedEdit}>
              <MdDelete
                color={selectedEdit?.deliveryTypeDTO === 'LELKES_SELF_PRINT' ? 'var(--primary-error)' : 'disabled'}
              />
            </IconButton>
          </div>
        );
      }
    }
  ];

  const [formDataEdit, setFormDataEdit] = useState({
    name: '',
    description: '',
    category: '',
    priority: 1,
    unitPrice: {
      netAmount: 0,
      grossAmount: 0,
      vatAmount: 0,
      taxRate: 0,
      currency: 'HUF'
    },
    enabled: true,
    additionalModifiers: [],
    compatibleWith: []
  });

  useEffect(() => {
    if (selectedEdit !== null) {
      setFormDataEdit({
        ...selectedEdit
      });
      setIsEditing(false);
    }
  }, [selectedEdit]);

  return (
    <Grow in={true}>
      <div className={styles.editContainer}>
        <div className={styles.editHeader}>
          <div className={styles.title}>Termék kategória szerkesztése</div>
          <Button
            className={styles.buttonClose}
            onClick={() => {
              onClose();
            }}>
            <MdClose size={'30px'} />
          </Button>
        </div>
        <div className={styles.editMainContainer}>
          <Formik
            enableReinitialize
            initialValues={formDataEdit}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              handleSubmit(values);
              resetForm();
            }}>
            {({ values, handleChange, handleSubmit }) => (
              <form className={styles.leftEdit}>
                <TextField
                  variant='outlined'
                  label='Név'
                  name='name'
                  size='small'
                  disabled={(selectedEdit?.deliveryTypeDTO !== 'LELKES_SELF_PRINT' && !!selectedEdit) || !isEditing}
                  value={values.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}></TextField>
                <TextField
                  variant='outlined'
                  label='Leírás'
                  name='description'
                  size='small'
                  disabled={(selectedEdit?.deliveryTypeDTO !== 'LELKES_SELF_PRINT' && !!selectedEdit) || !isEditing}
                  value={values.description}
                  onChange={(e) => {
                    handleChange(e);
                  }}></TextField>
                <FormControl variant='outlined' size='small' fullWidth>
                  <InputLabel>Kategória címke</InputLabel>
                  <Select
                    label='Kategória címke'
                    name='category'
                    value={values.category ?? ''}
                    onChange={handleChange}
                    disabled={(selectedEdit?.deliveryTypeDTO !== 'LELKES_SELF_PRINT' && !!selectedEdit) || !isEditing}>
                    <MenuItem value='Formátum'>Formátum</MenuItem>
                    <MenuItem value='Terméktípus'>Terméktípus</MenuItem>
                    <MenuItem value='Méret'>Méret</MenuItem>
                  </Select>
                </FormControl>

                <Button
                  disabled={
                    (selectedEdit?.deliveryTypeDTO !== 'LELKES_SELF_PRINT' && !!selectedEdit) ||
                    createModifier.isPending ||
                    updateModifier.isPending ||
                    createPrio2Modifier.isPending ||
                    updatePrio2Modifier.isPending
                  }
                  onClick={() =>
                    selectedEdit === null ? handleSubmit() : isEditing ? handleSubmit() : setIsEditing(true)
                  }
                  type={!isEditing ? 'submit' : 'button'}>
                  {createModifier.isPending || updateModifier.isPending ? (
                    <CircularProgress size={23} />
                  ) : selectedEdit === null ? (
                    'Kategória létrehozása'
                  ) : isEditing ? (
                    'Kategória mentése'
                  ) : (
                    'Kategória szerkesztése'
                  )}
                </Button>
                {isEditing && selectedEdit?.deliveryTypeDTO === 'LELKES_SELF_PRINT' && (
                  <Button
                    onClick={() => {
                      setIsEditing(false);
                    }}>
                    Mégsem
                  </Button>
                )}
                {!!selectedEdit && selectedEdit?.deliveryTypeDTO === 'LELKES_SELF_PRINT' ? (
                  datagridData.length > 1 ? (
                    <Tooltip title='A kategória törléséhez előbb törölnöd kell a hozzá tartozó termékeket!'>
                      <Button onClick={() => {}} style={{ marginTop: 'auto' }}>
                        Kategória törlése
                        <MdDelete size={'25px'} color='var(--primary-error)' />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      onClick={() => {
                        deleteModifier.mutate({ modifierId: selectedEdit.id, setOpen: setIsEdit });
                      }}
                      style={{ marginTop: 'auto' }}>
                      Kategória törlése
                      <MdDelete size={'25px'} color='var(--primary-error)' />
                    </Button>
                  )
                ) : null}
              </form>
            )}
          </Formik>
          <Divider />
          <div className={styles.rightEdit}>
            {(selectedEdit?.deliveryTypeDTO === 'LELKES_SELF_PRINT' || !selectedEdit) && (
              <Button
                disabled={!selectedEdit}
                className={styles.buttonAdd}
                onClick={() => {
                  setDialogOpen(true);
                }}>
                Új termék hozzáadása
                <MdAdd size={'25px'} />
              </Button>
            )}

            <DataGrid columns={columns} rows={datagridData} filterMode='client'></DataGrid>
          </div>
        </div>
        <ProductTypeDialog
          open={dialogOpen}
          onClose={handleDialogClose}
          onSubmit={handleDialogSubmit}
          selectedEdit={selectedEdit}
          edit={edit}
        />
        <ConfirmDialog
          open={sureOpen}
          onClose={() => setSureOpen(null)}
          title={'Biztosan törölni szeretnéd?'}
          onOk={() => deleteModifier.mutate({ modifierId: sureOpen, setOpen: setSureOpen })}
          multipleButtons={true}
          functionButtonText={'Törlés'}
          closeButtonText={'Mégsem'}
        />
      </div>
    </Grow>
  );
};

export default ProductTypeEditor;
