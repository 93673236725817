import { MdImage, MdAdd, MdEmail, MdPerson } from 'react-icons/md';
import React from 'react';
import styles from './ProductTypes.module.css';
import SpecialCard from 'components/common/SpecialCard/SpecialCard';

const ProductTypes = ({ handleProductTypeSelect, handleAddNew, currentModifiers }) => {
  const SpecialIcon = (isEnabled) => {
    return (
      <div className={styles.special_icon}>
        <MdPerson size={'75px'} color={isEnabled ? 'var(--primary-background-primary)' : '#8a8888'} />
        <div className={styles.small_icon}>
          <MdImage size={'30px'} color={isEnabled ? 'var(--primary-background-primary)' : '#8a8888'} />
        </div>
      </div>
    );
  };

  return (
    <SpecialCard title='Termék típusok szerkesztése' sx={styles.left}>
      <div className={styles.productTypeContainer}>
        {currentModifiers?.map((el) => {
          if (el.priority === 1) {
            const isEnabled = currentModifiers?.find((mod) => mod.id === el.id)?.enabled;

            return (
              <div
                key={el.id}
                className={`${styles.card} ${isEnabled ? styles.cardEnabled : ''}`}
                onClick={() => handleProductTypeSelect(el)}>
                <div>
                  {el.deliveryTypeDTO === 'POST' ? (
                    <MdImage size={'75px'} color={isEnabled ? 'var(--primary-background-primary)' : '#8a8888'} />
                  ) : el.deliveryTypeDTO === 'LELKES_SELF_PRINT' ||
                    el.deliveryTypeDTO === 'SELF' ||
                    el.deliveryTypeDTO === 'POST_AND_LELKES_PRINT' ? (
                    <SpecialIcon isEnabled={isEnabled} />
                  ) : (
                    <MdEmail size={'75px'} color={isEnabled ? 'var(--primary-background-primary)' : '#8a8888'} />
                  )}
                </div>
                <div>{el.name}</div>
              </div>
            );
          }
          return null;
        })}
        <div className={styles.card} onClick={handleAddNew}>
          <MdAdd size={'100px'} />
        </div>
      </div>
    </SpecialCard>
  );
};

export default ProductTypes;
