import React from "react";
import styles from "./Center.module.css";

const Center = React.memo(({ element, children, color = "", ...props }) => {
  const Tag = element;

  return (
    <Tag
      className={styles.center}
      {...props}
      style={{ backgroundColor: color }}
    >
      <div>{children}</div>
    </Tag>
  );
});

Center.displayName = "Center";

export default Center;
