import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import styles from './ErrorDialog.module.css';

const ErrorDialog = ({ open, onClose }) => (
  <Dialog open={open} onClose={onClose} className={styles.dialog}>
    <DialogTitle>{'Művelet sikertelen!'}</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Hiba történt a művelet végrehajtása során. Amennyiben sablont törölne, először bizonyosodjon meg arról, hogy az
        nincs használatban.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>
        Rendben
      </Button>
    </DialogActions>
  </Dialog>
);

export default ErrorDialog;
