/** @format */

import Grid from '@material-ui/core/Grid';

import InvoiceVendorForm from 'components/PhotoAdminComponents/Settings/Invoicing/InvoiceVendorForm/InvoiceVendorForm';
import { useInvoicingSettingsAPI } from './hooks/useInvoicingSettingsAPI';
import { useEffect, useState } from 'react';
import { CircularProgress, Slide, Typography } from '@material-ui/core';
import { useRef } from 'react';
import styles from './Invoicing.module.css';
import InvoiceVendorCard from 'components/PhotoAdminComponents/Settings/Invoicing/InvoiceVendorCard/InvoiceVendorCard';
import CreateInvoiceVendorCard from 'components/PhotoAdminComponents/Settings/Invoicing/CreateInvoiceVendorCard/CreateInvoiceVendorCard';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import ModifierInvoiceVendors from 'components/PhotoAdminComponents/Settings/Invoicing/ModifierInvoiceVendors/ModifierInvoiceVendors';
import { Breadcrumbs, Container, CssBaseline } from '@mui/material';

const Invoicing = () => {
  const {
    getInvoiceVendors,
    invoiceVendors,
    invoiceVendorsLoading,
    invoiceVendorsError,
    postInvoiceVendor,
    invoiceVendorLoading,
    putInvoiceVendor,
    deleteInvoiceVendor,
    modifiers,
    shopId,
    user,
    disabled
  } = useInvoicingSettingsAPI();
  const [newVendor, setNewVendor] = useState(false);
  const [editVendor, setEditVendor] = useState(false);

  const [deleteVendor, setDeleteVendor] = useState(null);

  useEffect(() => {
    getInvoiceVendors({});
  }, [shopId]);

  const containerRef = useRef(null);

  if (invoiceVendorsError) {
    return (
      <div className={styles.errorLoading}>
        <Typography variant='h6' color='primary'>
          Hiba történt. Kérjük próbálja újra később.
        </Typography>
      </div>
    );
  }

  if (invoiceVendorsLoading) {
    return (
      <div className={styles.errorLoading}>
        <Typography variant='h6' color='primary'>
          Kérem várjon...
        </Typography>
      </div>
    );
  }

  return (
    <Container maxWidth={false} className={styles.root}>
      <CssBaseline />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography style={{ fontFamily: 'var(--font-main)' }} variant='body1'>
          Számlakibocsátó szolgáltatások
        </Typography>
      </Breadcrumbs>
      {newVendor ? (
        <Grid
          item
          innerRef={containerRef}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Slide container={containerRef.current} direction='up' in={true} timeout={700}>
            <Grid key={`invoice_vendor_creating_card`} item xs={12} md={8} lg={6}>
              <InvoiceVendorForm
                user={user}
                handleSave={postInvoiceVendor}
                invoiceVendor={null}
                loading={invoiceVendorLoading}
                setOpen={() => setNewVendor(false)}
              />
            </Grid>
          </Slide>
        </Grid>
      ) : editVendor ? (
        <Grid
          item
          innerRef={containerRef}
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Slide container={containerRef.current} direction='up' in={true} timeout={700}>
            <Grid key={`invoice_vendor_creating_card`} item xs={12} md={8} lg={6}>
              <InvoiceVendorForm
                disabled={disabled}
                handleSave={putInvoiceVendor}
                invoiceVendor={editVendor}
                loading={invoiceVendorLoading}
                setOpen={() => setEditVendor(null)}
              />
            </Grid>
          </Slide>
        </Grid>
      ) : (
        <Grid
          item
          innerRef={containerRef}
          xs={12}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}>
          {(shopId === 132 ||
            invoiceVendors?.length === 0 ||
            modifiers?.find(
              (m) => m.deliveryTypeDTO === 'LELKES_SELF_PRINT' || m.modifierUriSegment?.includes('lelkesprint')
            )) && <CreateInvoiceVendorCard toggleCreateVendor={() => setNewVendor(true)} />}

          {invoiceVendors?.map((invoiceVendor) => (
            <InvoiceVendorCard
              disabled={disabled}
              invoiceVendor={invoiceVendor}
              setEditVendor={setEditVendor}
              toggleSure={() => {
                setDeleteVendor(invoiceVendor);
              }}
            />
          ))}
        </Grid>
      )}
      <ConfirmDialog
        open={deleteVendor}
        onClose={() => setDeleteVendor(null)}
        onOk={() => {
          deleteInvoiceVendor({
            vendorId: deleteVendor?.id,
            setOpen: () => setDeleteVendor(null)
          });
        }}
        disabled={invoiceVendorLoading}
        multipleButtons
        closeButtonText={'Mégsem'}
        functionButtonColor={'var(--primary-error)'}
        functionButtonText={invoiceVendorLoading ? <CircularProgress size={24} /> : 'Törlés'}
        title={`Figyelem! Törlés után a törölt számlakibocsátó által létrehozott korábbi számláid letöltésére nem lesz lehetőséged rendszerünkből, ezt csupán a számlakibocsátó rendszerén keresztül tudod majd megtenni. Biztosan törölni akarod a(z) ${deleteVendor?.name} nevű számlakibocsátódat?`}
      />
      {invoiceVendors?.length > 1 && !editVendor && !newVendor && (
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center'
          }}>
          <Typography
            style={{
              fontFamily: 'var(--font-main)',
              color: 'var(--primary-main)',
              width: '100%',
              textAlign: 'center',
              fontSize: '1.3rem',
              margin: '1rem 0'
            }}>
            Egyedi számlázás beállítása
          </Typography>
          <ModifierInvoiceVendors />
        </Grid>
      )}
    </Container>
  );
};

export default Invoicing;
