/** @format */

import { getApi } from '../axios';

export default {
  async getModifiers(shopId) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).get(`admin/shops/${shopId}/modifiers`);
  },
  async postModifier({ shopId, modifier }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).post(`admin/shops/${shopId}/modifiers`, modifier);
  },
  async putModifier({ shopId, modifier }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).put(`admin/shops/${shopId}/modifiers`, modifier);
  },
  async deleteModifier({ shopId, modifierId }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).delete(`admin/shops/${shopId}/modifiers/${modifierId}`);
  }
};
