import { createSlice } from '@reduxjs/toolkit';

export const shopSlice = createSlice({
  name: 'shop',
  initialState: {
    shopId: -1,
    allGroupsLength: null,
    allProductsLength: null
  },
  reducers: {
    setActive: (state, action) => {
      state.shopId = action.payload;
    },
    updateAllGroupsLength: (state, action) => {
      state.allGroupsLength = action.payload;
    },
    updateAllProductsLength: (state, action) => {
      state.allProductsLength = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setActive, updateAllGroupsLength, updateAllProductsLength } = shopSlice.actions;

export default shopSlice.reducer;
