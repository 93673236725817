import React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import styles from './TemplateSelector.module.css';

const TemplateSelector = ({ templates, loading, onTemplateSelect }) => (
  <>
    {loading ? (
      <LoadIndicator color={'var(--primary-text-primary)'} height={120} />
    ) : (
      <Select
        className={styles.select}
        variant='outlined'
        onChange={(e) => onTemplateSelect(e.target.value)}
        defaultValue={-1}
        style={{ fontFamily: 'var(--font-main), Sans-serif, Arial' }}>
        <MenuItem value={-1} disabled style={{ fontFamily: 'var(--font-main), Sans-serif, Arial' }}>
          Meglévő sablon választása
        </MenuItem>
        {templates &&
          templates?.map((template) => (
            <MenuItem
              key={`${template.id}_template`}
              value={template.id}
              style={{ fontFamily: 'var(--font-main), Sans-serif, Arial' }}>
              {template.name}
            </MenuItem>
          ))}
      </Select>
    )}
  </>
);

export default TemplateSelector;
