import { Typography, Select, MenuItem, FormControl, InputLabel, IconButton } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import styles from './StatusModifier.module.css';
import { useState, useEffect } from 'react';

const StatusModifier = ({ putOrder, order }) => {
  const [paymentStatus, setPaymentStatus] = useState('PENDING');
  const [invoiceStatus, setInvoiceStatus] = useState('NO_INVOICE');

  const handlePaymentStatusModify = () => {
    if (paymentStatus) {
      putOrder({ body: { ...order, status: paymentStatus } });
    }
  };

  const handleInvoiceStatusModify = () => {
    if (invoiceStatus) {
      if (invoiceStatus === 'INVOICED' && (order?.status === 'PAID' || order?.status === 'PENDING')) {
        putOrder({ body: { ...order, invoiceState: invoiceStatus, status: 'INVOICED' } });
      } else {
        putOrder({ body: { ...order, invoiceState: invoiceStatus } });
      }
    }
  };

  useEffect(() => {
    if (order) {
      setPaymentStatus(order?.status || 'PENDING');
      setInvoiceStatus(order?.invoiceState || 'NO_INVOICE');
    }
  }, [order]);

  return (
    <>
      <div className={styles.titleContainer}>
        <Typography className={styles.title}>Státusz választó</Typography>
      </div>
      <div className={styles.orderDetail}>
        <div className={styles.selectContainer}>
          <div className={styles.statusRow}>
            <FormControl className={styles.formControl}>
              <InputLabel>Rendelés státusz</InputLabel>
              <Select value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
                <MenuItem value='PENDING'>Függőben</MenuItem>
                <MenuItem value='PAID'>Fizetve</MenuItem>
                <MenuItem value='INVOICED'>Számlázva</MenuItem>
                <MenuItem value='SHIPPING'>Szállítás alatt</MenuItem>
                <MenuItem value='DONE'>Kész</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              color='primary'
              onClick={handlePaymentStatusModify}
              disabled={!paymentStatus || paymentStatus === order?.status}
              className={styles.checkButton}>
              <CheckIcon />
            </IconButton>
          </div>

          <div className={styles.statusRow}>
            <FormControl className={styles.formControl}>
              <InputLabel>Számla státusz</InputLabel>
              <Select value={invoiceStatus} onChange={(e) => setInvoiceStatus(e.target.value)}>
                <MenuItem value='NO_INVOICE'>Nincs számla</MenuItem>
                <MenuItem value='INVOICED'>Számlázva</MenuItem>
              </Select>
            </FormControl>
            <IconButton
              color='primary'
              onClick={handleInvoiceStatusModify}
              disabled={!invoiceStatus || invoiceStatus === order?.invoiceState}
              className={styles.checkButton}>
              <CheckIcon />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusModifier;
