import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import styles from './ProductTypeDialog.module.css';
import Divider from 'components/common/Divider/Divider';
import { Formik } from 'formik';

const ProductTypeDialog = ({ open, onClose, onSubmit, selectedEdit, edit }) => {
  const initialValues = {
    name: edit?.name ?? '',
    description: edit?.description ?? '',
    category: edit?.category ?? ''
  };

  return (
    <Dialog open={open} onClose={onClose} className={styles.dialog}>
      <h3 className={styles.dialogH3}>Új termék hozzáadása</h3>
      <Divider isHorizontal={true} />
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          onSubmit(
            edit
              ? { ...edit, name: values?.name, description: values?.description, category: values?.category }
              : {
                  name: values?.name,
                  description: values?.description,
                  category: values?.category,
                  priority: 2,
                  unitPrice: {
                    netAmount: 0,
                    grossAmount: 0,
                    vatAmount: 0,
                    taxRate: 0,
                    currency: 'HUF'
                  },
                  enabled: true,
                  additionalModifiers: [],
                  compatibleWith: [selectedEdit?.id]
                }
          );
          setSubmitting(false);
          resetForm();
        }}>
        {({ values, handleChange, handleSubmit }) => (
          <form className={styles.dialogContainer}>
            <TextField
              name='name'
              variant='outlined'
              label='Név'
              size='small'
              value={values.name}
              onChange={handleChange}
            />
            <TextField
              name='description'
              variant='outlined'
              label='Leírás'
              size='small'
              value={values.description}
              onChange={handleChange}
            />
            <TextField
              name='category'
              select
              variant='outlined'
              label='Kategória címke'
              size='small'
              value={values.category}
              onChange={handleChange}>
              <MenuItem value='Formátum'>Formátum</MenuItem>
              <MenuItem value='Terméktípus'>Terméktípus</MenuItem>
              <MenuItem value='Termék'>Termék</MenuItem>
              <MenuItem value='Méret'>Méret</MenuItem>
            </TextField>
            <Button className={styles.buttonRed} type='submit' onClick={handleSubmit}>
              Termék létrehozása
            </Button>
            <Button className={styles.buttonDark} onClick={onClose}>
              Mégsem
            </Button>
          </form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ProductTypeDialog;
