import React from 'react';
import { DatePickerField } from 'components/common/DatePickerField/DatePickerField';
import { useState } from 'react';
import { useEffect } from 'react';
import { useGetQueryString } from 'hooks/useGetQueryString';
import styles from './DateEditor.module.css';

const DateEditor = ({ option, setOption }) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const { getDateString } = useGetQueryString();
  const getFromDate = (option) => {
    if (option.value === '') return new Date(new Date().setUTCHours(0, 0, 0, 0));
    return new Date(option.value?.split('&')[0].split('=')[1]);
  };
  const getToDate = (option) => {
    if (option.value === '') return new Date(new Date().setUTCHours(23, 59, 59, 999));
    return new Date(option.value?.split('&')[1].split('=')[1]);
  };
  useEffect(() => {
    setFromDate(getFromDate(option));
    setToDate(getToDate(option));
    setOption({
      ...option,
      value: `${option.name}FromDateTime=${getDateString(getFromDate(option))}&${option.name}ToDateTime=${getDateString(
        getToDate(option)
      )}`
    });
  }, []);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.datePickerWrapper}>
        <DatePickerField
          name={`${option.name}_fromDate`}
          openTo='month'
          setDate={(dateValue) => {
            let newDateValue = new Date(dateValue.setUTCHours(0, 0, 0, 0));
            setFromDate(newDateValue);
            setOption({
              ...option,
              value: `${option.name}FromDateTime=${getDateString(newDateValue)}&${
                option.name
              }ToDateTime=${getDateString(toDate)}`
            });
          }}
          views={['year', 'month', 'day']}
          value={fromDate}
          fullWidth
        />
      </div>
      <div className={styles.datePickerWrapper}>
        <DatePickerField
          name={`${option.name}_toDate`}
          openTo='month'
          minDate={fromDate}
          setDate={(dateValue) => {
            let newDateValue = new Date(dateValue.setUTCHours(23, 59, 59, 999));
            newDateValue.setUTCDate(newDateValue.getUTCDate() + 1);
            setToDate(newDateValue);
            setOption({
              ...option,
              value: `${option.name}FromDateTime=${getDateString(fromDate)}&${option.name}ToDateTime=${getDateString(
                newDateValue
              )}`
            });
          }}
          views={['year', 'month', 'day']}
          value={new Date(toDate).setUTCDate(toDate.getUTCDate() - 1)}
          fullWidth
        />
      </div>
    </div>
  );
};

export default DateEditor;
