import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CssBaseline, Container, Typography } from '@material-ui/core';
import styles from './SelectShop.module.css';
import ShopItem from './ShopItem/ShopItem';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import AddShopCard from 'components/PhotoAdminComponents/SelectShop/AddShopCard/AddShopCard';
import { useShopAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useShopApi';

const SelectShop = (props) => {
  const { getShops, shopsLoading, createShop, shopLoading } = useShopAPI();

  const user = useSelector((state) => state.nonPersistedReducers.userSliceReducer.currentUser);

  useEffect(() => {
    getShops();
  }, []);

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Container maxWidth={'xl'}>
        <main className={styles.content}>
          <div className={styles.appBarSpacer} />

          {shopsLoading ? (
            <LoadIndicator color={'var(--primary-light)'} height={300} />
          ) : (
            <>
              <div className={styles.shopWrapper}>
                <div className={styles.shopCreator}>
                  {!user?.ownedShops[0] && (
                    <div style={{ height: '100%' }}>
                      <Typography>Még nincs saját boltja? Hozzon létre egyet!</Typography>
                      <AddShopCard user={user} createShop={createShop} shopLoading={shopLoading} />
                    </div>
                  )}
                </div>
              </div>
              <Typography className={styles.shopsTitle} style={{ textAlign: 'center' }}>
                Az Ön boltja
              </Typography>
              <div className={styles.shopWrapper}>
                {user?.ownedShops?.map((shop) => {
                  return <ShopItem key={`shop_${shop.id}`} id={shop.id} name={shop.name} />;
                })}
              </div>
              <Typography className={styles.shopsTitle} style={{ textAlign: 'center' }}>
                Az Ön által kezelt boltok
              </Typography>
              <div className={styles.shopWrapper}>
                {user?.maintainedShops?.map((shop) => {
                  return <ShopItem key={`shop_${shop.id}`} id={shop?.shop?.id} name={shop?.shop?.name} />;
                })}
              </div>
            </>
          )}
        </main>
      </Container>
    </div>
  );
};

export default SelectShop;
