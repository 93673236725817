import { useState, useEffect } from 'react';
import { useModificationLogic } from 'components/PhotoAdminComponents/ModifierForm/hooks/useModificationLogic';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import queries from 'api/queries/ProductQueries';
import { errorToast, successToast } from 'components/common/Toast/Toast';

export const useProductTypesLogic = () => {
  const [selectedEdit, setSelectedEdit] = useState(null);
  const [prio2Edit, setPrio2Edit] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [datagridData, setDatagridData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const { getUriId, clearModifiers } = useModificationLogic();

  const queryClient = useQueryClient();

  const {
    data: currentModifiers,
    isLoading,
    isError
  } = useQuery({
    queryKey: ['currentModifiers'],
    queryFn: () => queries.getModifiers(shopId)
  });

  useEffect(() => {
    if (selectedEdit) {
      createDatagridData(selectedEdit);
    }
  }, [currentModifiers?.data]);

  const createModifier = useMutation({
    mutationFn: (modifier) => queries.postModifier({ shopId: shopId, modifier: modifier }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['currentModifiers'] });
      successToast('Sikeres létrehozás!');
      setSelectedEdit(data?.data);
    },
    onError: () => {
      errorToast('Sikertelen létrehozás!');
    }
  });
  const createPrio2Modifier = useMutation({
    mutationFn: (modifier) => queries.postModifier({ shopId: shopId, modifier: modifier }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['currentModifiers'] });
      console.log(selectedEdit, data);
      updateModifier.mutate({
        ...selectedEdit,
        compatibleWith:
          selectedEdit?.compatibleWith?.length > 0
            ? [...selectedEdit?.compatibleWith, data?.data?.id]
            : [data?.data?.id]
      });
      successToast('Sikeres létrehozás!');
    },
    onError: () => {
      errorToast('Sikertelen létrehozás!');
    }
  });
  const updateModifier = useMutation({
    mutationFn: (modifier) => queries.putModifier({ shopId: shopId, modifier: modifier }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['currentModifiers'] });
      successToast('Sikeres szerkesztés!');
      setSelectedEdit(data?.data);
    },
    onError: () => {
      errorToast('Sikertelen szerkesztés!');
    }
  });
  const updatePrio2Modifier = useMutation({
    mutationFn: (modifier) => queries.putModifier({ shopId: shopId, modifier: modifier }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['currentModifiers'] });
      successToast('Sikeres szerkesztés!');
    },
    onError: () => {
      errorToast('Sikertelen szerkesztés!');
    }
  });
  const deleteModifier = useMutation({
    mutationFn: (variables) => queries.deleteModifier({ shopId: shopId, modifierId: variables?.modifierId }),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({ queryKey: ['currentModifiers'] });
      variables?.setOpen(false);
      successToast('Sikeres törlés!');
    },
    onError: () => {
      errorToast('Sikertelen törlés!');
    }
  });

  const handleSubmit = (values) => {
    if (selectedEdit === null) {
      createModifier.mutate(values);
    } else {
      updateModifier.mutate(values);
    }
  };

  const createDatagridData = (data) => {
    setDatagridData([]);
    if (!data) return;

    const datas = currentModifiers?.data
      .filter((el) => {
        const segmentId = getUriId(el.modifierUriSegment);
        return el.priority === 2 && data.compatibleWith?.includes(segmentId);
      })
      .map((el) => ({
        id: el.id,
        name: el.name,
        description: el.description,
        category: el.category
      }));
    setDatagridData(datas);
  };

  const handleProductTypeSelect = (productType) => {
    setSelectedEdit(productType);

    createDatagridData(productType);
    setIsEdit(true);
  };

  const handleAddNew = () => {
    setSelectedEdit(null);
    setIsEdit(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogSubmit = async (values) => {
    if (prio2Edit) {
      updatePrio2Modifier.mutate(values);
      handleDialogClose();
      setPrio2Edit(null);
    } else {
      createPrio2Modifier.mutate(values);
      handleDialogClose();
    }
  };

  const resetForm = () => {
    setSelectedEdit(null);
    setDatagridData([]);
    setIsEdit(false);
  };

  return {
    selectedEdit,
    setSelectedEdit,
    prio2Edit,
    setPrio2Edit,
    isEdit,
    setIsEdit,
    datagridData,
    setDatagridData,
    dialogOpen,
    setDialogOpen,
    createModifier,
    createPrio2Modifier,
    updateModifier,
    updatePrio2Modifier,
    deleteModifier,
    handleProductTypeSelect,
    handleAddNew,
    handleDialogClose,
    handleDialogSubmit,
    resetForm,
    handleSubmit,
    currentModifiers,
    isLoading,
    isError
  };
};
