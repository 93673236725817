import { Typography } from '@material-ui/core';
import { getApi } from 'api/axios';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useModificationLogic } from '../hooks/useModificationLogic';

const ActualCommission = ({ modifierId, modifierUriSegment, minimals, netAmount, taxRate, modifierDeliveryType }) => {
  const [printingPrice, setPrintingPrice] = useState();
  const [profit, setProfit] = useState();

  const { getUriId } = useModificationLogic();

  const getActualProfit = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL })
      ).post(`/admin/quotes/calculate/commissions/inverse`, [
        {
          modifierId: getUriId(modifierUriSegment),
          itemPrice: {
            grossAmount: 0,
            taxRate: 0,
            currency: 'HUF'
          },
          formulaParameters: {
            commissionInverse: [
              {
                formulaKey: 'userInput',
                formulaValue: netAmount
              }
            ]
          }
        }
      ]);
      setProfit(res?.data?.find((price) => price.modifierId === getUriId(modifierUriSegment))?.price?.grossAmount);
    } catch (e) {
      console.log(e);
    }
  };

  const getPrintingPrice = async () => {
    try {
      const res = await (
        await getApi({ baseUrl: window._env_.REACT_APP_QDAK_ORDER_URL })
      ).get(`/admin/delivery-vendor-options/latest`);
      setPrintingPrice(
        res?.data?.find(
          (price) =>
            price.modId === getUriId(modifierUriSegment) &&
            (price.deliveryType === modifierDeliveryType ||
              (price.deliveryType === 'LELKES_SELF' && modifierDeliveryType === 'LELKES_SELF_PRINT'))
        )?.price?.grossAmount
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    //console.log(profit);
    getPrintingPrice();
    getActualProfit();
  }, [modifierId, minimals, netAmount, taxRate]);

  return (
    <Typography
      style={{
        color: profit - printingPrice > 0 ? 'var(--primary-success)' : profit === 0 ? 'black' : 'var(--primary-error)',
        fontWeight: 'bold'
      }}>
      {profit - printingPrice > 0 && '+'} {!isNaN(printingPrice) ? profit - printingPrice : profit}
    </Typography>
  );
};

export default ActualCommission;
