import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import PrintIcon from '@mui/icons-material/Print';
import styles from './PrinterAdminSite.module.css';
import Checkbox from '@mui/material/Checkbox';
import { Button, Tooltip, Typography, Container, CssBaseline, Breadcrumbs, Zoom } from '@material-ui/core';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import QueryBuilder from 'components/common/QueryBuilder/QueryBuilder';
import { usePrinterApi } from './hooks/usePrinterApi';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import Center from 'components/common/Center/Center';

const PrinterAdminSite = () => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [showAll, setShowAll] = useState(false);

  const [allSelected, setAllSelected] = useState(false);

  const [open, setOpen] = useState();

  const { fetchPrintableGroups, printGroupFinal, groups, groupsLength, setGroups, loading } = usePrinterApi();

  const selectGroup = (groups, currentgroup) => {
    const newGroups = groups.map((group) => {
      if (group.id === currentgroup.id) {
        return { ...group, selected: !group.selected };
      }
      return group;
    });
    setGroups(newGroups);
  };

  const getShowAllQueryString = () => {
    let queryParams = new URLSearchParams(search);
    queryParams.set('page', 1);
    queryParams.set('size', groupsLength);
    return queryParams.toString();
  };
  const handleShowAll = () => {
    setShowAll(!showAll);

    navigate(`/printer${getShowAllQueryString()}`);
  };
  const handleSelectAll = (groups, e) => {
    setAllSelected(e.target.checked);
    const newGroups = groups.map((group) => ({
      ...group,
      selected: e.target.checked
    }));
    setGroups(newGroups);
  };

  const handlePrint = async () => {
    let promises = [];
    groups?.forEach(async (group) => {
      if (group.selected) {
        let newBody = { ...group };
        delete newBody.selected;
        newBody.isPrintingDone = true;
        let promise = printGroupFinal({
          group: { ...newBody }
        });
        promises.push(promise);
      }
    });
    await Promise.all(promises);
    await fetchPrintableGroups({
      queryString: search
    });
  };

  const refresh = () => {
    fetchPrintableGroups({ queryString: search });
  };

  const linkRef = useRef(null);

  useEffect(() => {
    refresh();
    let queryParams = new URLSearchParams(search);

    if (queryParams.get('size') !== groupsLength) {
      setShowAll(false);
    }
  }, [search]);

  const resetQueryString = () => {
    navigate('/printer');
  };

  return (
    <Center element='section'>
      <CssBaseline />
      <a ref={linkRef} />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography variant='body1'>Nyomtatásra beérkezett galériák</Typography>
      </Breadcrumbs>
      <Zoom in={true} timeout={1000}>
        <Container maxWidth={false} className={styles.container}>
          <QueryBuilder
            itemsLength={groupsLength}
            showAll={showAll}
            siteType={'printer'}
            reset={resetQueryString}
            refresh={refresh}>
            <table className={styles.headerTop}>
              <tbody>
                <tr width='100%' className={styles.searchBar}>
                  <td
                    style={{
                      color: 'whitesmoke',
                      textAlign: 'center'
                    }}>
                    <Tooltip title={'Kijelöltek nyomtatásának véglegesítése'}>
                      <Button
                        variant='outlined'
                        style={{
                          backgroundColor: 'var(--primary-background-content)',
                          color: 'var(--primary-main)',
                          margin: 0,
                          border: `2px solid var(--primary-background-primary)`,
                          transition: 'opacity 300ms',
                          opacity: !groups?.find((group) => group.selected) ? 0.3 : 1
                        }}
                        disabled={!groups?.find((group) => group.selected)}
                        onClick={() => setOpen('printConfirm')}>
                        <PrintIcon />
                        Nyomtatás véglegesítése
                      </Button>
                    </Tooltip>
                    <ConfirmDialog
                      open={open === 'printConfirm'}
                      title='Biztosan Véglegesíted a nyomtatást? Ezáltal a fotósoknak is visszajelzést küldünk, hogy a kijelölt iskolák nyomtatása megtörtént!'
                      functionButtonText='Nyomtatás véglegesítése'
                      functionButtonColor='var(--primary-success)'
                      onOk={async () => {
                        setOpen(null);
                        await handlePrint();
                        setAllSelected(false);
                      }}
                      onClose={() => setOpen(null)}
                      closeButtonText='Mégsem'
                      multipleButtons
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.deliveryListWrapper}>
              <div className={styles.tableContainer}>
                <table className={styles.header}>
                  <thead>
                    <tr width='100%'>
                      <td className={`${styles.heading} ${styles.checkboxColumn}`}>
                        <Checkbox
                          style={{ color: 'whitesmoke' }}
                          checked={allSelected}
                          onChange={(e) => handleSelectAll(groups, e)}></Checkbox>
                      </td>
                      <td className={`${styles.heading} ${styles.indexColumn} ${styles.hideXs}`}>
                        <Typography className={styles.heading}>#</Typography>
                      </td>
                      <td className={`${styles.heading} ${styles.idColumn}`}>
                        <Typography className={styles.heading}>Azonosító</Typography>
                      </td>
                      <td className={`${styles.heading} ${styles.shopIdColumn} ${styles.hideXs}`}>
                        <Typography className={styles.heading}>Bolt azonosító</Typography>
                      </td>
                      <td className={`${styles.heading} ${styles.nameColumn}`}>
                        <Typography className={styles.heading}>Iskola neve</Typography>
                      </td>
                      <td className={`${styles.heading} ${styles.dateColumn}`}>
                        <Typography className={styles.heading}>Létrehozás dátuma</Typography>
                      </td>
                      <td className={`${styles.heading} ${styles.statusColumn}`}>
                        <Typography className={styles.heading}>Státusz</Typography>
                      </td>
                    </tr>
                  </thead>
                </table>

                <table className={styles.listitemtable}>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td>
                          <LoadIndicator color='var(--primary-light)' height={800} />
                        </td>
                      </tr>
                    ) : groups?.length > 0 ? (
                      groups?.map((group, idx) => (
                        //Frontend filtering for showing only printable paper deliveries, FOR TEST ONLY!!!!
                        //delivery.deliveryType === "POST" &&
                        <tr
                          className={styles.listItem}
                          style={{ background: idx % 2 === 1 && 'lightgray' }}
                          width='100%'
                          key={group.id}
                          onClick={(e) => {
                            selectGroup(groups, group);
                            e.stopPropagation();
                          }}>
                          <td className={`${styles.tableCell} ${styles.checkboxColumn}`}>
                            <Checkbox
                              style={{
                                color: 'var(--primary-background-secondary)'
                              }}
                              checked={group.selected || allSelected}
                              onChange={() => {
                                selectGroup(groups, group);
                              }}></Checkbox>
                          </td>
                          <td className={`${styles.tableCell} ${styles.indexColumn} ${styles.hideXs}`}>
                            <Typography className={styles.tableCell}>{idx + 1}</Typography>
                          </td>
                          <td className={`${styles.tableCell} ${styles.idColumn}`}>
                            <Typography className={styles.tableCell}>{group.id}</Typography>
                          </td>
                          <td className={`${styles.tableCell} ${styles.shopIdColumn} ${styles.hideXs}`}>
                            <Typography className={styles.tableCell}>{group.shopId}</Typography>
                          </td>
                          <td className={`${styles.tableCell} ${styles.nameColumn}`}>
                            <Typography className={styles.tableCell}>{group?.name}</Typography>
                          </td>
                          <td className={`${styles.tableCell} ${styles.dateColumn}`}>
                            {group?.createdAt && (
                              <Typography className={styles.tableCell}>
                                {new Date(group?.createdAt).toLocaleDateString('hu-HU', {
                                  hour: '2-digit',
                                  minute: '2-digit',
                                  seconds: '2-digit'
                                })}
                              </Typography>
                            )}
                          </td>
                          <td className={`${styles.tableCell} ${styles.statusColumn}`}>
                            <Typography
                              style={{
                                fontFamily: 'var(--font-main)',
                                color: group?.isActive ? 'rgb(25,109,235)' : 'var(--primary-error)'
                              }}>
                              {group?.isActive ? 'Aktív' : 'Inaktív'}
                            </Typography>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr
                        style={{
                          margin: '0.5rem',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center'
                        }}>
                        <td>
                          <Typography variant='h5' style={{ color: 'var(--primary-light)' }}>
                            Nincs nyomtatható galéria.
                          </Typography>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </QueryBuilder>

          <div
            className={styles.allButton}
            onClick={() => {
              handleShowAll();
            }}>
            {showAll ? 'Kevesebb galéria' : 'Összes galéria'}
          </div>
        </Container>
      </Zoom>
    </Center>
  );
};

export default PrinterAdminSite;
