/** @format */

import { getApi } from '../axios';

export default {
  async getPayments(queryString) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).get(`admin/payments${queryString}`);
  },
  async patchPayment({ shopId, id, status }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).patch(`admin/shops/${shopId}/payments/${id}/status/${status}`);
  },
  async registerCard({ shopId }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).post(`/shops/${shopId}/card-info`);
  },
  async getRegistration({ shopId }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).get(`/shops/${shopId}/card-info`);
  },
  async deleteRegistration({ shopId }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).delete(`/shops/${shopId}/card-info`);
  },
  async getRecurringProperties() {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).get(`admin/payments/recurring-properties`);
  }
};
