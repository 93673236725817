import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Paper, Switch } from '@mui/material';
import Zoom from '@material-ui/core/Zoom';
import Delete from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import DialogContentText from '@material-ui/core/DialogContentText';
import Tooltip from '@mui/material/Tooltip';
import styles from './GroupItem.module.css';
import { useGroupAPI } from 'pages/PhotoAdmin/SelectShop/hooks/useGroupAPI';
import { useGetQueryString } from 'hooks/useGetQueryString';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import { amber } from '@mui/material/colors';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import queries from 'api/queries/ProductQueries';

const GroupItem = ({ disabled, group, isSchool, selectGroups, selected, putGroup, refetch }) => {
  const navigate = useNavigate();

  const {
    fetchCover,
    cover,
    coverLoading,
    deleteGroup,
    fetchProducts,
    products,
    productsLength,
    fetchInnerGroups,
    innerGroups
  } = useGroupAPI();

  const [active, setActive] = useState(group.isActive);
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);
  const [hide, setHide] = useState(false);
  const [alert, setAlert] = useState(false);
  const [recursiveAlert, setRecursiveAlert] = useState(false);
  const [count, setCount] = useState(undefined);
  const [giftValidity, setGiftValidity] = useState(null);
  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);
  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);
  const allGroupsLength = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.allGroupsLength);
  const allProductsLength = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.allProductsLength);

  const [activationDisabled, setActivationDisabled] = useState(false);

  useEffect(() => {
    if (allGroupsLength >= shopTier?.galleryLimit || allProductsLength?.length >= shopTier?.productsLimit) {
      setActivationDisabled(true);
    } else {
      setActivationDisabled(false);
    }
  }, [allGroupsLength, user, shopId, shopTier, allProductsLength]);

  // If true, changes made to the group is applied to its descendant groups, recursively
  const [inheritHeader, setinheritHeader] = useState(false);

  const { currentPage, pageSize, getProductQueryString } = useGetQueryString();

  const { data: modifiers } = useQuery({
    queryKey: ['modifiers', shopId],
    queryFn: () => queries.getModifiers(shopId),
    enabled: !!shopId
  });

  useEffect(() => {
    fetchCover({ id: group.id });
    if (isSchool) {
      fetchInnerGroups({ id: group.id });
    } else {
      fetchProducts({
        id: group.id,
        queryString: getProductQueryString({
          page: currentPage,
          size: pageSize,
          sort: 'name'
        })
      });
    }
  }, [currentPage, group.id, isSchool, pageSize]);

  useEffect(() => {
    if (isSchool) {
      if (innerGroups?.length !== undefined) {
        setCount(innerGroups?.length);
      }
    } else {
      if (productsLength !== null) {
        setCount(productsLength);
      }
      if (productsLength === null) {
        setCount(0);
      }
    }
  }, [products, innerGroups, productsLength, isSchool]);

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 20) {
      displayedName = name.slice(0, 9) + '...' + name.slice(name.length - 9, name.length);
    }

    return displayedName;
  };

  const switchAuth = () => {
    if (group.groups) {
      setRecursiveAlert(true);
    } else {
      switchChanged();
    }
  };

  const switchChanged = () => {
    setRecursiveAlert(false);
    var json = '';
    if (group.isActive) {
      json = JSON.stringify(group).replace('"isActive":true', '"isActive":false');
    } else {
      json = JSON.stringify(group).replace('"isActive":false', '"isActive":true');
    }
    let body = { ...JSON.parse(json) };
    if (body.inheritModifiers) {
      body.modifierTemplate = null;
    }
    delete body.selected;
    setActive(!active);
    putGroup({ id: group.id, body: body });
  };

  const routeChange = (id) => {
    let path = `/shops/${shopId}/administration/galleries/${id}`;
    navigate(path);
  };

  const handleDelete = async () => {
    await deleteGroup({ id: group.id });
    await refetch();
    setAlert(false);
    setHide(true);
  };

  const getUriId = (segment) => {
    return +segment.match('mod#id:[0-9]*')[0]?.split(':')[1];
  };

  useEffect(() => {
    group?.productGroupModifierValidities?.find(
      (el) =>
        el?.modifierUriSegment?.includes('id:44') ||
        (el?.modifierUriSegment?.includes('lelkesprint') && !el?.modifierUriSegment?.includes('mod#id:21'))
    )
      ? setGiftValidity(
          group?.productGroupModifierValidities?.find(
            (el) =>
              el?.modifierUriSegment?.includes('id:44') ||
              (el?.modifierUriSegment?.includes('lelkesprint') && !el?.modifierUriSegment?.includes('mod#id:21'))
          )
        )
      : setGiftValidity(null);
  }, [group?.productGroupModifierValidities]);

  const getValidUntilFields = () => {
    // Get all priority 1 modifiers with their validities
    const priorityOneModifiers = modifiers?.data
      ?.filter(
        (modifier) =>
          modifier.priority === 1 &&
          group?.productGroupModifierValidities?.findLast(
            (el) => getUriId(modifier.modifierUriSegment) === getUriId(el.modifierUriSegment)
          )
      )

      ?.map((modifier) => {
        const validity = group?.productGroupModifierValidities?.findLast(
          (el) => getUriId(modifier.modifierUriSegment) === getUriId(el.modifierUriSegment)
        );

        return {
          name: modifier.name,
          validUntil: validity?.validUntil?.substring(0, 10) || 'Lejárt'
        };
      });

    return (
      <div className={styles.validThru}>
        {priorityOneModifiers?.map((modifier, index) => (
          <Typography key={index} className={styles.validThruInner}>
            {modifier.name}: {modifier.validUntil}
          </Typography>
        ))}
      </div>
    );
  };

  return (
    <>
      {hide ? (
        <></>
      ) : (
        <Zoom in={true} timeout={1000}>
          <Card className={styles.cardStyle} component={Paper} count={count}>
            <div
              className={styles.selectContainer}
              style={{
                display: (isSchool || group.name === 'Csoportképek' || group.name === 'Group photos') && 'none'
              }}
              onClick={() => selectGroups()}>
              <input className={styles.groupSelect} color='primary' type='checkbox' checked={selected} name='' id='' />
            </div>
            <Switch
              className={styles.switch}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: 'var(--primary-background-secondary)'
                },
                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                  backgroundColor: 'var(--primary-background-secondary)'
                }
              }}
              onChange={() => switchAuth()}
              disabled={activationDisabled}
              checked={active}
            />
            <IconButton className={styles.delete} onClick={() => setAlert(true)}>
              <Delete />
            </IconButton>
            <CardActionArea
              style={{
                opacity: active ? '100%' : '50%',
                border:
                  count === 0
                    ? `4px solid var(--primary-error)`
                    : group?.isPrinted
                    ? `4px solid ${amber[500]}`
                    : group?.isPrintingDone
                    ? `4px solid var(--primary-success)`
                    : '4px solid transparent'
              }}
              className={styles.cardActionArea}
              onClick={() => {
                if (disabled) return;
                routeChange(group.id);
              }}>
              {coverLoading ? (
                <LoadIndicator></LoadIndicator>
              ) : (
                <CardMedia className={styles.media} image={cover}>
                  <div></div>
                </CardMedia>
              )}
              <Tooltip title={group.name}>
                <CardContent className={styles.cardContent}>
                  <Typography className={styles.cardTitle} variant='body1'>
                    {calcName(group.name)}
                  </Typography>
                  <div className={styles.searchKeyContainer}>
                    <p>{group?.accessKey}</p>
                    <IconButton
                      disabled={!group?.isActive}
                      type='button'
                      aria-label='toggle copy to clipboard'
                      onClick={(e) => {
                        navigator?.clipboard?.writeText(group?.accessKey);

                        e.stopPropagation();
                      }}>
                      <ContentCopyIcon />
                    </IconButton>
                  </div>
                  {shopTier?.galleryExpiryDate ? getValidUntilFields() : <></>}
                  <div className={styles.countwrapper}>
                    {
                      <Typography
                        className={styles.itemData}
                        style={{
                          opacity: !isNaN(count) && count !== undefined && '1',
                          fontFamily: `var(--font-main), Sans-serif, Arial`
                        }}
                        component='p'
                        variant='body1'>
                        {isSchool ? `${count} Osztály` : `${count} Kép`}
                      </Typography>
                    }
                  </div>
                </CardContent>
              </Tooltip>
            </CardActionArea>
          </Card>
        </Zoom>
      )}

      <Dialog
        open={alert}
        onClose={() => setAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle className={styles.customDialogTitle} id='alert-dialog-title'>
          {'Biztosan törölni szeretnéd?'}
        </DialogTitle>
        <DialogActions className={styles.dialogActions}>
          <Button className={styles.customButton} onClick={() => handleDelete()} color='primary'>
            Törlés
          </Button>
          <Button className={styles.customButton} onClick={() => setAlert(false)} color='primary'>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={recursiveAlert}
        onClose={() => setRecursiveAlert(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle className={styles.customDialogTitle} id='alert-dialog-title'>
          <div style={{ textAlign: 'center' }}>Figyelmeztetés</div>
        </DialogTitle>
        <DialogContentText className={styles.checkbox}>
          Szeretném hogy a belső galériákat is megváltoztassa:{' '}
          <Checkbox color='primary' checked={inheritHeader} onChange={(e) => setinheritHeader(e.target.checked)} />
        </DialogContentText>
        <DialogActions className={styles.dialogActions}>
          <Button className={styles.customButton} onClick={() => switchChanged()} color='primary'>
            Folytatás
          </Button>
          <Button className={styles.customButton} onClick={() => setRecursiveAlert(false)} color='primary'>
            Mégse
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GroupItem;
