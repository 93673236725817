import { useNavigate } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Paper } from "@material-ui/core";
import Zoom from "@material-ui/core/Zoom";
import Tooltip from "@mui/material/Tooltip";
import styles from './ShopItem.module.css';
import cover from "assets/images/test.jpg";
import { useDispatch } from "react-redux";
import { setActive } from "redux/slices/ShopSlice";

const ShopItem = ({ id, name }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const routeChange = () => {
    navigate(`/shops/${id}/administration/orders`);
    dispatch(setActive(id));
  };

  return (
    <Zoom in={true} timeout={1000}>
      <Card className={styles.cardStyle} component={Paper}>
        <CardActionArea
          className={styles.cardActionArea}
          onClick={routeChange}
        >
          <CardMedia className={styles.media} image={cover}><div></div></CardMedia>
          <Tooltip title={name}>
            <CardContent className={styles.cardContent}>
              <Typography className={styles.cardTitle} variant="body1">
                {name}
              </Typography>
            </CardContent>
          </Tooltip>
        </CardActionArea>
      </Card>
    </Zoom>
  );
};

export default ShopItem;
