import React from 'react';
import styles from './specialCard.module.css';
import { classNames } from '../../../utils/react_utils';
import { Grow } from '@mui/material';
const SpecialCard = ({ sx, children, title }) => {
  const style = classNames(styles.card, sx);

  return (
    <Grow in={true}>
      <div className={style}>
        <div className={styles.title}>{title}</div>
        {children}
      </div>
    </Grow>
  );
};

export default SpecialCard;
