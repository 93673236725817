import React from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserApi } from '../hooks/useUserApi';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CssBaseline from '@material-ui/core/CssBaseline';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import styles from './User.module.css';
import { Button, Link, Typography } from '@material-ui/core';
import { useShopAPI } from '../hooks/useShopApi';
import ConfirmDialog from 'components/common/ConfirmDialog/ConfirmDialog';
import { useState } from 'react';

const User = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { getGalleriesOfShop, galleriesOfShop } = useShopAPI();
  const { fetchUser, user, putUser } = useUserApi();

  const getNumberOfActiveGroups = (groups) => {
    let numberOfActiveGroups = 0;
    groups?.forEach((group) => group?.isActive && numberOfActiveGroups++);
    return numberOfActiveGroups;
  };

  const handleStatus = async (user) => {
    const newUser = { ...user, active: !user?.active };
    await putUser(newUser);
    fetchUser(id);
  };

  useEffect(() => {
    if (id !== null) {
      fetchUser(id);
    }
  }, []);

  useEffect(() => {
    if (user !== null) {
      getGalleriesOfShop(user?.ownedShops[0]?.id);
    }
  }, [user]);

  return (
    <>
      {user && (
        <div className={styles.root}>
          <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
            <Link onClick={() => navigate('/superadmin/users')}>
              {' '}
              <Typography color='inherit' variant='body1' style={{ cursor: 'pointer' }}>
                Felhasználók kezelése
              </Typography>
            </Link>
            <Typography>{user?.username}</Typography>
          </Breadcrumbs>
          <div>
            <CssBaseline />
            <main className={styles.content}>
              <Container maxWidth={'xl'} className={styles.container}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={8} lg={6}>
                    <Paper className={styles.paper}>
                      <Typography className={styles.title}>Kapcsolati adatok</Typography>
                      <div className={styles.row}>
                        <p className={styles.title}>Név:</p>
                        <p className={styles.detail}>{`${user?.contact?.firstname} ${user?.contact?.lastname}`}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Felhasználónév:</p>
                        <p className={styles.detail}>{user?.username}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Email:</p>
                        <p className={styles.detail}>{user?.contact?.email}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Telefon:</p>
                        <p className={styles.detail}>{user?.contact?.phone}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Cím:</p>
                        <p
                          className={
                            styles.detail
                          }>{`${user?.contact?.address?.zipCode},${user?.contact?.address?.city} ${user?.contact?.address?.streetAndNumber}`}</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={4} lg={6}>
                    <Paper className={styles.paper}>
                      <Typography className={styles.title}>Számlázási adatok</Typography>
                      <div className={styles.row}>
                        <p className={styles.title}>Név:</p>
                        <p className={styles.detail}>{`${user?.contact?.firstname} ${user?.contact?.lastname}`}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Adószám:</p>
                        <p className={styles.detail}>{user?.billingInfo?.vatNumber}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Cím:</p>
                        <p
                          className={
                            styles.detail
                          }>{`${user?.billingInfo?.billingAddress?.zipCode},${user?.billingInfo?.billingAddress?.city} ${user?.billingInfo?.billingAddress?.streetAndNumber}`}</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={styles.paper}>
                      <Typography className={styles.title}>Bolt adatok</Typography>
                      <div className={styles.row}>
                        <p className={styles.title}>Bolt neve:</p>
                        <p className={styles.detail}>{user.ownedShops[0]?.name}</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Galériák száma:</p>
                        <p className={styles.detail}>{galleriesOfShop?.length ? galleriesOfShop?.length : '0'} db</p>
                      </div>
                      <div className={styles.row}>
                        <p className={styles.title}>Aktív galériák száma:</p>
                        <p className={styles.detail}>{`${getNumberOfActiveGroups(galleriesOfShop)} db`}</p>
                      </div>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Paper className={styles.paper}>
                      <Typography className={styles.title}>Fiók állapota</Typography>
                      <div className={styles.row}>
                        <Typography
                          style={{
                            fontWeight: 'bold',
                            color: user?.active ? 'var(--primary-success)' : 'var(--primary-error)'
                          }}>
                          {user?.active ? 'Aktív' : 'Inaktív'}
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                  {/*  {JSON.stringify(user)}
                   {galleriesOfShop && JSON.stringify(galleriesOfShop)} */}
                </Grid>
                <Button
                  onClick={() => {
                    setOpen(true);
                  }}
                  className={styles.button}>
                  {user?.active ? 'Fiók deaktiválása' : 'Fiók aktiválása'}
                </Button>
              </Container>
              <ConfirmDialog
                open={open}
                onClose={() => setOpen(false)}
                onOk={() => {
                  handleStatus(user);
                  setOpen(false);
                }}
                title={user?.active ? 'Biztosan deaktiválni akarod a fiókot?' : 'Biztosan aktiválni akarod a fiókot?'}
                closeButtonText={'Nem'}
                functionButtonText={'Igen'}
                functionButtonColor={'var(--primary-success)'}
                multipleButtons={true}
              />
            </main>
          </div>
        </div>
      )}
    </>
  );
};

export default User;
