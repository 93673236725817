import { useEffect, useRef } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import styles from './AdminMenu.module.css';
import { useNavigate, Outlet } from 'react-router-dom';
import { Divider, Paper, Tab, Tabs, Tooltip } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import HeaderData from 'config/HeaderData';
import { useSelector } from 'react-redux';

const AdminMenu = ({ match }) => {
  const navigate = useNavigate();
  const uploadLoading = useSelector((state) => state.nonPersistedReducers.uploadSliceReducer.loading);

  const shopId = useSelector((state) => state.nonPersistedReducers?.shopSliceReducer?.shopId);

  const user = useSelector((state) => state.nonPersistedReducers?.userSliceReducer?.currentUser);
  const tabsActions = useRef();

  useEffect(() => {
    setTimeout(() => {
      if (tabsActions?.current) {
        tabsActions.current.updateIndicator();
      }
    }, 1000);
  }, [tabsActions]);

  return (
    <div className={styles.root}>
      <CssBaseline />
      <Grow in={true} timeout={1000}>
        <Paper className={styles.settingsContainer}>
          <Tabs
            TabIndicatorProps={{
              style: { background: 'var(--primary-main)' }
            }}
            action={tabsActions}
            orientation='vertical'
            variant='fullWidth'
            value={
              HeaderData.options.admin.find((option) => window.location.pathname.toString().includes(option.to))?.to ||
              `${match?.url}/orders`
            }
            className={styles.tabs}>
            {HeaderData.options.admin.map((option, idx) =>
              (option.to === 'templates' || option.to === 'subscriptions' || option.to === 'photographer-profile') &&
              ((user?.ownedShops[0]?.id !== shopId &&
                !user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes?.includes('MANAGE_PRICING') &&
                !user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes?.includes('VIEW_PRICING')) ||
                user?.ownedShops[0]?.id === shopId) ? (
                option?.to === 'subscriptions' || option?.to === 'photographer-profile' ? null : (
                  <Tab
                    selected={window.location.pathname.toString().includes(option.to)}
                    value={option.to}
                    disabled={option.disabled || uploadLoading}
                    style={{
                      minWidth: '50px',
                      minHeight: '65px',
                      height: '65px',
                      fontFamily: 'var(--font-main) !important'
                    }}
                    onClick={() => navigate(`${option.to}`)}
                    label={`${option.name}`}
                    key={`${idx}_option_tab`}
                  />
                )
              ) : option.to === 'galleries' &&
                user?.ownedShops[0]?.id !== shopId &&
                !user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes?.includes('VIEW_PRODUCTS') ? (
                <Tab
                  selected={window.location.pathname.toString().includes(option.to)}
                  value={option.to}
                  disabled={option.disabled || uploadLoading}
                  style={{
                    minWidth: '50px',
                    minHeight: '36px',
                    height: '36px',
                    fontFamily: 'var(--font-main) !important'
                  }}
                  onClick={() => navigate(`${option.to}`)}
                  label={`${option.name}`}
                  key={`${idx}_option_tab`}
                />
              ) : option.to === 'roles' &&
                !user?.ownedShops?.find((shop) => shop?.id === shopId) &&
                !user?.maintainedShops?.find((shop) => shop.shop.id === shopId)?.scopes?.includes('VIEW_SHOP') ? (
                <Tooltip title='Ezt a felületet csak a megfelelő BOLT_MEGTEKINTÉSE jogosultság birtokában érheti el'>
                  <Tab
                    disabled={option.disabled || uploadLoading}
                    value={option.to}
                    selected={window.location.pathname.toString().includes(option.to)}
                    style={{
                      minWidth: '50px',
                      minHeight: '36px',
                      height: '36px',
                      fontFamily: 'var(--font-main) !important'
                    }}
                    onClick={() => {}}
                    label={`${option.name}`}
                    key={`${idx}_option_tab`}
                  />
                </Tooltip>
              ) : option.to === 'invoicing' &&
                !user?.ownedShops?.find((shop) => shop?.id === shopId) &&
                !user?.maintainedShops
                  ?.find((shop) => shop.shop.id === shopId)
                  ?.scopes?.includes('VIEW_INVOICE_VENDORS') ? (
                <Tooltip title='Ezt a felületet csak a megfelelő SZÁMLÁZÓ_VENDOROK_MEGTEKINTÉSE jogosultság birtokában érheti el'>
                  <Tab
                    disabled={option.disabled || uploadLoading}
                    value={option.to}
                    selected={window.location.pathname.toString().includes(option.to)}
                    style={{
                      minWidth: '50px',
                      minHeight: '36px',
                      height: '36px',
                      fontFamily: 'var(--font-main) !important'
                    }}
                    onClick={() => {}}
                    label={`${option.name}`}
                    key={`${idx}_option_tab`}
                  />
                </Tooltip>
              ) : (
                <Tab
                  disabled={option.disabled || uploadLoading}
                  value={option.to}
                  selected={window.location.pathname.toString().includes(option.to)}
                  style={{
                    minWidth: '50px',
                    minHeight: '36px',
                    height: '36px',
                    fontFamily: 'var(--font-main) !important'
                  }}
                  onClick={() => navigate(`${option.to}`)}
                  label={`${option.name}`}
                  key={`${idx}_option_tab`}
                />
              )
            )}
            {/*
           NEEDED IN PHOTOGRAPHER PROFILE VERSION
           <Divider />
            <Tab
              selected={window.location.pathname?.includes('/photographer-profile')}
              value={'photographer-profile'}
              disabled={uploadLoading}
              style={{
                minWidth: '50px',
                minHeight: '36px',
                height: '36px',
                margin: '0.5rem 0'
              }}
              onClick={() => navigate(`photographer-profile`)}
              label={`Fotós profil`}
              key={`photographer-profile_option_tab`}
            />*/}
            <Divider />
            <Tab
              selected={window.location.pathname?.includes('/subscriptions')}
              value={'subscriptions'}
              disabled={uploadLoading}
              style={{
                minWidth: '50px',
                minHeight: '65px',
                height: '65px',
                fontFamily: 'var(--font-main) !important'
              }}
              onClick={() => navigate(`subscriptions`)}
              label={`Előfizetői beállítások`}
              key={`subscriptions_option_tab`}
            />
          </Tabs>
          <Outlet />
        </Paper>
      </Grow>
    </div>
  );
};

export default AdminMenu;
