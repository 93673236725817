import { Breadcrumbs, Button, CircularProgress, Container, CssBaseline, Grid, Typography } from '@material-ui/core';
import { useTemplatesLogic } from './useTemplatesLogic';
import TemplateDetails from 'components/PhotoAdminComponents/Templates/TemplateDetails/TemplateDetails';
import React from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { MdClose } from 'react-icons/md';
import styles from './Templates.module.css';
import TemplateMain from 'components/PhotoAdminComponents/Templates/TemplateMain/TemplateMain';
import { Grow } from '@mui/material';
import ErrorDialog from 'components/PhotoAdminComponents/Templates/ErrorDialog/ErrorDialog';
import TemplateSelector from 'components/PhotoAdminComponents/Templates/TemplateSelector/TemplateSelector';
import ActionButtons from 'components/PhotoAdminComponents/Templates/ActionButtons/ActionButtons';

const Templates = () => {
  const {
    templates,
    templatesLoading,
    template,
    templateLoading,
    modifiers,
    isTemplate,
    setIsTemplate,
    clickedTemplate,
    setClickedTemplate,
    selectedTemplate,
    formChanged,
    setFormChanged,
    disabled,
    setDisabled,
    currentModifiers,
    setCurrentModifiers,
    name,
    setName,
    buttonRef,
    openDeleteDialog,
    setOpenDeleteDialog,
    openUpdateDialog,
    setOpenUpdateDialog,
    openCreateDialog,
    setOpenCreateDialog,
    isDialogOpen,
    setIsDialogOpen,
    confirmSelect,
    handleTemplateOperations,
    createProgress,
    updateProgress,
    deleteProgress
  } = useTemplatesLogic();

  return (
    <Container maxWidth={false} className={styles.root}>
      <CssBaseline />
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography style={{ fontFamily: 'var(--font-main)' }} variant='body1'>
          Termékek és sablonok
        </Typography>
      </Breadcrumbs>
      <TemplateMain modifiers={modifiers} isTemplate={isTemplate} setIsTemplate={setIsTemplate} />

      {isTemplate ? (
        <Grow in={true}>
          <div className={styles.container}>
            <div className={styles.sideContainer}>
              <Grid container>
                <div className={styles.topContainer}>
                  <TemplateSelector
                    templates={templates}
                    loading={templatesLoading}
                    onTemplateSelect={setClickedTemplate}
                  />
                  <Button
                    className={styles.buttonClose}
                    onClick={() => {
                      setIsTemplate(false);
                    }}>
                    <MdClose size={'30px'} />
                  </Button>
                </div>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    className={styles.loadButton}
                    variant='contained'
                    disabled={!clickedTemplate || templatesLoading || templateLoading}
                    onClick={confirmSelect}>
                    {templateLoading ? (
                      <CircularProgress
                        size={25}
                        style={{
                          color: 'var(--primary-text-primary)'
                        }}
                      />
                    ) : (
                      'Betölt'
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {currentModifiers && (
                  <TemplateDetails
                    id={selectedTemplate || null}
                    name={name}
                    setName={setName}
                    modifiers={currentModifiers}
                    selectedTemplate={template}
                    setFormChanged={setFormChanged}
                    technical={false}
                    setModifiers={setCurrentModifiers}
                    buttonRef={buttonRef}
                    setDisabled={setDisabled}
                  />
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography
                  style={{
                    color: 'var(--primary-text-primary)',
                    opacity: disabled ? 1 : 0,
                    transition: 'opacity 300ms'
                  }}>
                  <ErrorIcon
                    style={{
                      fontSize: '1rem',
                      margin: 'auto 10px',
                      color: 'red'
                    }}
                  />
                  {'Az kiválasztott típusok árai nem lehetnek kevesebbek a minimális értéküknél'}
                </Typography>
              </Grid>
              <ActionButtons
                selectedTemplate={selectedTemplate}
                formChanged={formChanged}
                disabled={disabled}
                currentModifiers={currentModifiers}
                onDelete={() => setOpenDeleteDialog(true)}
                onUpdate={() => setOpenUpdateDialog(true)}
                onCreate={() => setOpenCreateDialog(true)}
                handleDelete={handleTemplateOperations.delete}
                handlePut={handleTemplateOperations.update}
                handlePost={handleTemplateOperations.create}
                openCreateDialog={openCreateDialog}
                setOpenCreateDialog={setOpenCreateDialog}
                openUpdateDialog={openUpdateDialog}
                setOpenUpdateDialog={setOpenUpdateDialog}
                openDeleteDialog={openDeleteDialog}
                setOpenDeleteDialog={setOpenDeleteDialog}
                progress={{ createProgress, updateProgress, deleteProgress }}
              />
            </div>
          </div>
        </Grow>
      ) : null}
      <ErrorDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
    </Container>
  );
};

export default Templates;
