import { Zoom, Typography } from '@material-ui/core';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React from 'react';
import styles from './CreateInvoiceVendorCard.module.css';
import { useSelector } from 'react-redux';

const CreateInvoiceVendorCard = ({ toggleCreateVendor }) => {
  const shopTier = useSelector((state) => state.nonPersistedReducers?.shopTierSliceReducer?.shopTier);

  return (
    <Zoom in={true} timeout={1000}>
      <div className={shopTier?.automaticInvoice ? styles.cardStyle : styles.cardStyleDisabled}>
        <div
          disabled={!shopTier?.automaticInvoice}
          className={styles.cardActionArea}
          onClick={() => {
            if (shopTier?.automaticInvoice) {
              toggleCreateVendor();
            }
          }}>
          <AddCircleIcon className={styles.addCircle} />
          <Typography className={styles.cardTitle}>Számlakibocsátó hozzáadása</Typography>
        </div>
      </div>
    </Zoom>
  );
};

export default CreateInvoiceVendorCard;
