import {
  Breadcrumbs,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Zoom
} from '@material-ui/core';
import LoadIndicator from 'components/common/LoadIndicator/LoadIndicator';
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserApi } from './hooks/useUserApi';
import styles from './Users.module.css';

const Users = () => {
  const navigate = useNavigate();

  const { loading, users, fetchUsers } = useUserApi();

  const routeChange = (id) => {
    navigate(`${id}`);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className={styles.root}>
      <Breadcrumbs aria-label='breadcrumb' className={styles.breadcrumb}>
        <Typography color='inherit' variant='body1'>
          Felhasználók kezelése
        </Typography>
      </Breadcrumbs>
      {loading ? (
        <LoadIndicator color={'var(--primary-light)'} height={202} />
      ) : (
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid xs={12} md={8} item>
            <Zoom in={true} timeout={700}>
              <Paper className={styles.paper}>
                <Table size='small'>
                  <TableHead
                    style={{
                      backgroundColor: 'var(--primary-background-secondary)',
                      color: 'whitesmoke'
                    }}>
                    <TableRow>
                      <TableCell align='center'>
                        <Typography className={styles.heading}>#</Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography className={styles.heading}>Azonosító</Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography className={styles.heading}>Felhasználó név</Typography>
                      </TableCell>
                      <TableCell align='center'>
                        <Typography className={styles.heading}>Státusz</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users?.map((user, idx) => (
                        <TableRow
                          onClick={() => {
                            routeChange(user?.id);
                          }}
                          key={`order_${idx}`}
                          className={styles.tableRow}>
                          <TableCell align='center'>
                            <Typography>{idx + 1}</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography>{user?.id}</Typography>
                          </TableCell>
                          <TableCell align='center'>
                            <Typography>{user?.username}</Typography>
                          </TableCell>

                          <TableCell align='center'>
                            <Typography>{user?.active === true ? 'Aktív' : 'Inaktív'}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>{' '}
              </Paper>
            </Zoom>{' '}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Users;
