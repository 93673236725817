import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import { useOrderAPI } from '../../hooks/useOrderAPI';
import { useEffect } from 'react';
import { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import styles from './OrderList.module.css';

const getUnitPrice = (params) => {
  return params.value.grossAmount;
};
const getTotalPrice = (params) => {
  return params.value;
};

const getImageSize = (params) => {
  var param = params.row.productName;

  return param?.split(' ')?.splice(1)?.join(' ');
};
// const getImageSize = (params) => {
//   var param = params.row.productName
//     .split(" ")
//     .filter(
//       (part) =>
//         ((part.includes("x") && part.length < 10) ||
//           part === "Bélyegkép" ||
//           part === "Igazolványkép" ||
//           part === "Eredeti" ||
//           (part.includes("A4") && !part.includes(".jpg") && !part.includes(".jpeg") && !part.includes(".JPG"))) &&
//         part
//     );
//   if (param.length === 0) {
//     return ["-"];
//   } else if (param.find((p) => p.includes("A4"))) {
//     return ["A4"];
//   } else {
//     return param;
//   }
// };

// const getImageFormat = (params) => {
//   let format = params.row.modifierUri.includes('email')
//     ? 'Digitális'
//     : params.row.productName.split(' ')[1]?.match('^[0-9]db|[0-9]DB$')
//     ? 'Nyomtatott'
//     : params.row.productName.split(' ')[2] === 'db'
//     ? 'Nyomtatott'
//     : params.row.productName
//         .split(' ')
//         .splice(1, params.row.productName.split(' ').length - 1)
//         .join(' ');
//   return (
//     <Tooltip title={format}>
//       <p>{format}</p>
//     </Tooltip>
//   );
// };

const getProductName = (params) => {
  let productName = params.row.productName.split(' ')[0];
  return (
    <Tooltip title={productName}>
      <p>{productName}</p>
    </Tooltip>
  );
};

const ImageComponent = ({ id, alt }) => {
  const { fetchProductImage, productImage } = useOrderAPI();

  useEffect(() => {
    fetchProductImage({ id: id });
  }, []);

  return <img src={productImage} style={{ width: '90px' }} alt={alt} />;
};

const OrderList = ({ cartItems, shopId }) => {
  function CustomToolbar() {
    return (
      <GridToolbarContainer className={styles.toolbar}>
        <GridToolbarExport csvOptions={{ allColumns: true, utf8WithBom: true }}></GridToolbarExport>
      </GridToolbarContainer>
    );
  }

  const getRows = (cartItems) => {
    return cartItems.map((item) => ({
      ...item,
      totalPrice: parseFloat(item.unitPrice.grossAmount) * parseInt(item.quantity)
    }));
  };

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!!cartItems) {
      const sortedItems = [...cartItems].sort((a, b) => (a.productName > b.productName ? 1 : -1));
      setRows(getRows(sortedItems));
    }
  }, [cartItems]);

  const columns = [
    // {
    //   headerAlign: "center",
    //   field: "productId",
    //   headerName: "ID",
    //   flex: 1,
    //   align: "center",
    //   sortable: false,
    //   resizable: false,
    //   editable: false,
    //   disableColumnMenu: true,
    //   renderHeader: (params) => <strong>Azonosító</strong>,
    // },

    {
      headerAlign: 'center',
      field: 'imageId',
      headerName: 'Kép',
      disableExport: true,
      flex: 1,
      renderCell: (params) => (
        <ImageComponent shopId={shopId} id={params.row.productId} alt={`${params.row.productId}_preview`} />
      ),

      align: 'center',
      sortable: false,
      resizable: false,
      editable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <strong>Kép</strong>
    },
    {
      headerAlign: 'center',
      field: 'productName',
      headerName: 'Kép',
      disableExport: true,
      flex: 1,
      renderCell: (params) => getProductName(params),
      align: 'center',
      sortable: false,
      resizable: false,
      editable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <strong>Fájlnév</strong>
    },
    {
      headerAlign: 'center',
      field: 'modifierIds',
      headerName: 'Méret és típus',
      flex: 2,
      valueGetter: getImageSize,
      align: 'center',
      sortable: false,
      resizable: false,
      editable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <strong>Méret és típus</strong>
    },
    // {
    //   headerAlign: "center",
    //   field: "modifierIds",
    //   headerName: "Kép méret",
    //   flex: 1,
    //   valueGetter: getImageSize,
    //   align: "center",
    //   sortable: false,
    //   resizable: false,
    //   editable: false,
    //   disableColumnMenu: true,
    //   renderHeader: (params) => <strong>Kép méret</strong>,
    // },
    // {
    //   headerAlign: "center",
    //   field: "modifierTypes",
    //   headerName: "Formátum",
    //   flex: 1,
    //   renderCell: getImageFormat,
    //   align: "center",
    //   sortable: false,
    //   resizable: false,
    //   editable: false,
    //   disableColumnMenu: true,
    //   renderHeader: (params) => <strong>Formátum</strong>,
    // },
    {
      headerAlign: 'center',
      field: 'unitPrice',
      headerName: 'Egységár (HUF)',
      flex: 1,
      valueGetter: getUnitPrice,
      align: 'center',
      sortable: false,
      resizable: false,
      editable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <strong>Egységár (HUF)</strong>
    },
    {
      headerAlign: 'center',
      field: 'quantity',
      headerName: 'Mennyiség',
      flex: 1,
      align: 'center',
      sortable: false,
      resizable: false,
      editable: false,
      disableColumnMenu: true,
      renderHeader: (params) => <strong>Mennyiség</strong>
    },
    {
      headerAlign: 'center',
      field: 'totalPrice',
      headerName: 'Ár (HUF)',
      flex: 1,
      align: 'center',
      sortable: false,
      resizable: false,
      editable: false,
      disableColumnMenu: true,
      valueGetter: getTotalPrice,
      renderHeader: (params) => <strong>Ár (HUF)</strong>
    }
  ];

  return (
    <>
      <div className={styles.gridContainer}>
        <DataGrid
          getRowId={(row) => `${row.productId}_${row.modifierUri}`}
          className={styles.grid}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          components={{
            Toolbar: CustomToolbar
          }}
          disableSelectionOnClick
        />
      </div>
    </>
  );
};

export default OrderList;
