/** @format */

import { getApi } from '../axios';

export default {
  async getPublicShopTiers() {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).get(`admin/shop-tiers?isPublic=true`);
  },
  async getShopTier({ shopId }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).get(`admin/shops/${shopId}/shop-tiers`);
  },
  async assignShopTier({ shopId, tierId, isYearlyPricing }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).post(`admin/shops/${shopId}/shop-tiers/${tierId}/assignments`);
  },
  async unassignShopTier({ shopId, tierId }) {
    return await (
      await getApi({
        baseUrl: window._env_.REACT_APP_QDAK_USER_URL,
        auth: true
      })
    ).delete(`admin/shops/${shopId}/shop-tiers/${tierId}/assignments`);
  }
};
