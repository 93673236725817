import { useState, useRef, useEffect } from 'react';
import { useModifierApi } from './hooks/useModifierApi';
import { useTemplateApi } from './hooks/useTemplateApi';
import { useModificationLogic } from 'components/PhotoAdminComponents/ModifierForm/hooks/useModificationLogic';

export const useTemplatesLogic = () => {
  const {
    templates,
    getTemplates,
    getTemplate,
    templatesLoading,
    postTemplate,
    putTemplate,
    deleteTemplate,
    templatesError,
    getTemplateBody,
    template,
    setTemplate,
    templateLoading,
    createProgress,
    updateProgress,
    deleteProgress
  } = useTemplateApi();
  const { getModifiers, modifiers } = useModifierApi();
  const { getCurrentModifiers, clearModifiers } = useModificationLogic();

  const [isTemplate, setIsTemplate] = useState(false);
  const [clickedTemplate, setClickedTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [formChanged, setFormChanged] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [currentModifiers, setCurrentModifiers] = useState([]);
  const [name, setName] = useState('Új sablon');
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const buttonRef = useRef(null);

  useEffect(() => {
    getTemplates();
    getModifiers();
  }, []);

  useEffect(() => {
    if (modifiers) {
      setCurrentModifiers(getCurrentModifiers([], modifiers));
    }
  }, [modifiers]);

  useEffect(() => {
    if (template) {
      setName(template?.name);
      if (template?.modifiers?.length > 0) {
        setCurrentModifiers(getCurrentModifiers(template, modifiers));
      }
    }
  }, [template]);

  useEffect(() => {
    if (templatesError) {
      setIsDialogOpen(true);
    }
  }, [templatesError]);

  const confirmSelect = () => {
    if (clickedTemplate && buttonRef.current) {
      setSelectedTemplate(clickedTemplate);
      getTemplate(clickedTemplate);
    }
  };

  const resetForm = () => {
    setName('Új sablon');
    setClickedTemplate(null);
    setSelectedTemplate(null);
    setCurrentModifiers(getCurrentModifiers([], modifiers));
    setTemplate(null);
  };

  const handleTemplateOperations = {
    delete: async () => {
      if (buttonRef.current) {
        await deleteTemplate(selectedTemplate);
        await getTemplates();
        resetForm();
      }
    },
    update: async () => {
      if (buttonRef.current) {
        await buttonRef.current.click();
        await putTemplate(getTemplateBody(selectedTemplate, name, clearModifiers(currentModifiers)));
        await getTemplates();
        resetForm();
      }
    },
    create: async () => {
      if (buttonRef.current) {
        await buttonRef.current.click();
        await postTemplate(getTemplateBody(null, name, clearModifiers(currentModifiers)));
        await getTemplates();
        resetForm();
      }
    }
  };

  return {
    templates,
    templatesLoading,
    templatesError,
    template,
    templateLoading,
    modifiers,
    isTemplate,
    setIsTemplate,
    clickedTemplate,
    setClickedTemplate,
    selectedTemplate,
    formChanged,
    setFormChanged,
    disabled,
    setDisabled,
    currentModifiers,
    setCurrentModifiers,
    name,
    setName,
    buttonRef,
    openDeleteDialog,
    setOpenDeleteDialog,
    openUpdateDialog,
    setOpenUpdateDialog,
    openCreateDialog,
    setOpenCreateDialog,
    isDialogOpen,
    setIsDialogOpen,
    confirmSelect,
    handleTemplateOperations,
    createProgress,
    updateProgress,
    deleteProgress
  };
};
