import { useState, useEffect, createRef, forwardRef } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import { Skeleton } from '@mui/material';
import ProductDetails from './ProductDetails/ProductDetails';
import styles from './Product.module.css';
import { useProductAPI } from './hooks/useProductAPI';
import { Slide, Typography } from '@material-ui/core';

export const Transition = forwardRef((props, ref) => {
  return <Slide direction='left' ref={ref} {...props} />;
});

const Product = ({ shopId, refresh, productHeader, modifiers, parentGroup, selectProduct, selected }) => {
  const { fetchProduct, product, fetchProductImage, productImage, deleteProduct } = useProductAPI();

  useEffect(() => {
    fetchProductImage({ id: productHeader.id });
  }, [productHeader.id]);

  const [hover, setHover] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const toggleOpen = async () => {
    await fetchProduct({ id: productHeader.id });
    setOpen(!open);
  };

  const editProductModalRef = createRef(null);

  const calcName = (name) => {
    let displayedName = name;

    if (name.length > 25) {
      displayedName = name.slice(0, 10) + '...' + name.slice(name.length - 10, name.length);
    }

    return displayedName;
  };

  const handleDelete = async () => {
    await deleteProduct({ id: productHeader.id });
    setConfirmOpen(false);
    setHover(false);
    refresh();
  };

  return (
    <Tooltip title={productHeader.name}>
      <div className={styles.productContainer}>
        {!productHeader?.isActive && <div className={styles.hiddenOverlay} />}
        <div className={styles.selectContainer}>
          <input
            className={styles.productSelect}
            onChange={selectProduct}
            color='primary'
            type='checkbox'
            checked={selected}
            name=''
            id=''
          />
        </div>

        <div
          className={styles.overlayContainer}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}>
          {hover && (
            <div
              className={styles.deleteContainer}
              onClick={(e) => {
                setConfirmOpen(true);
              }}>
              <DeleteIcon fontSize='large' />
            </div>
          )}
          {hover && (
            <div className={styles.editContainer} onClick={toggleOpen}>
              <Edit fontSize='large' />
            </div>
          )}
        </div>
        {productHeader && productImage ? (
          <div className={styles.productInnerContainer}>
            <div className={styles.productTop}>
              <div
                className={styles.mobileDeleteContainer}
                onClick={(e) => {
                  setConfirmOpen(true);
                }}>
                <div className={styles.mobileDeleteButton}>
                  <DeleteIcon fontSize='large' style={{ marginTop: '0.1rem' }} />
                </div>
              </div>
              <div className={styles.mobileEditContainer} onClick={toggleOpen}>
                <div className={styles.mobileEditButton}>
                  <Edit fontSize='large' style={{ marginTop: '0.1rem' }} />
                </div>
              </div>
              <img className={styles.productImage} src={productImage} alt={`${productHeader?.name}_thumbnail`} />
            </div>
            <div className={styles.productBottom}>
              <p className={styles.productTitle}>{calcName(productHeader?.name)}</p>
            </div>
          </div>
        ) : (
          <>
            <Skeleton variant='rectangular' width={'auto'} height={187} />
            <Skeleton variant='text' />
          </>
        )}
        <Dialog
          open={confirmOpen}
          onClose={(e) => {
            setConfirmOpen(false);
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle className={styles.customDialogTitle} id='alert-dialog-title'>
            <Typography
              style={{
                fontFamily: `var(--font-main), Sans-serif, Arial`
              }}>
              Biztosan törölni szeretnéd?
            </Typography>
          </DialogTitle>
          <DialogActions>
            <Button onClick={handleDelete} color='primary'>
              Törlés
            </Button>
            <Button
              onClick={() => {
                setConfirmOpen(false);
              }}
              color='primary'>
              Mégse
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          maxWidth={false}
          ref={editProductModalRef}
          onClose={() => setOpen(false)}
          aria-labelledby='alert-dialog-slide-title'
          aria-describedby='alert-dialog-slide-description'>
          {open && product && (
            <ProductDetails
              parentGroup={parentGroup}
              currentProduct={{
                ...productHeader,
                modifiers: product?.modifiers
              }}
              availableModifiers={modifiers}
              productImage={productImage}
              refresh={refresh}
              toggle={toggleOpen}></ProductDetails>
          )}
        </Dialog>
      </div>
    </Tooltip>
  );
};

export default Product;
