import { getApi } from 'api/axios';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { errorToast } from 'components/common/Toast/Toast';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBatchResults,
  clearUploads,
  decrementErrorCount,
  incrementCompletedUploads,
  incrementErrorCount,
  setFileCount,
  setUploadsLoading,
  updateBatchResults,
  updateResult
} from 'redux/slices/UploadSlice';
import AuthService from 'services/AuthService';

export const useUploaderBl = () => {
  const shopId = useSelector((state) => state.nonPersistedReducers.shopSliceReducer.shopId);

  const dispatch = useDispatch();

  const [batchSize, setbatchSize] = useState(window?._env_?.REACT_APP_UPLOAD_BATCH_SIZE || 5);

  const batchSizeRef = useRef(null);
  const submitbatchSize = () => {
    if (batchSizeRef.current) {
      let value = parseInt(batchSizeRef.current.value);
      if (isNaN(value)) {
        alert('Batch count must be a number.');
      } else {
        setbatchSize(value);
      }
    }
  };

  const fileInputRef = useRef(null);

  const handleInputClick = () => {
    fileInputRef.current.click();
  };

  const getFiles = (eventFiles) => {
    let files = [];
    for (let i = 0; i < eventFiles.length; i++) {
      files.push(eventFiles.item(i));
    }

    dispatch(setFileCount(files.length));

    return files;
  };

  const uploadRequest = async (batch, i, products) => {
    const api = await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL });

    if (products?.find((product) => product?.name == batch.promises[i].name?.split(' ')?.join('_'))) {
      dispatch(
        updateResult({
          promiseConfig: {
            name: batch.promises[i].name,
            url: batch.promises[i].url,
            form: batch.promises[i].form,
            config: batch.promises[i].config
          },
          success: isOk(201)
        })
      );
      dispatch(incrementCompletedUploads());
    } else {
      axiosRetry(api, {
        retries: 3,
        retryCondition(error) {
          return error.response.status > 401 ||
            (error.response.status === 400 &&
              !error?.response?.data?.message?.includes('101') &&
              !error?.response?.data?.message?.includes('Product limit reached'))
            ? true
            : false;
        },
        onRetry: (retryCount, error, requestConfig) => {
          console.log(`retry count: `, retryCount);
        }
      });
      await api
        .post(batch.promises[i].url, batch.promises[i].form, batch.promises[i].config)
        .then((result) => {
          dispatch(
            updateResult({
              promiseConfig: {
                name: batch.promises[i].name,
                url: batch.promises[i].url,
                form: batch.promises[i].form,
                config: batch.promises[i].config
              },
              success: isOk(result.status)
            })
          );
          dispatch(incrementCompletedUploads());
        })
        .catch((error) => {
          if (error?.response?.data?.message?.includes('101')) {
            dispatch(
              updateResult({
                promiseConfig: {
                  name: batch.promises[i].name,
                  url: batch.promises[i].url,
                  form: batch.promises[i].form,
                  config: batch.promises[i].config
                },
                success: isOk(201)
              })
            );
            dispatch(incrementCompletedUploads());
          } else if (error?.response?.data?.message?.includes('Product limit reached')) {
            errorToast('Képfeltöltési limit elérve! Élesítsd demo boltod!');
            dispatch(
              updateResult({
                promiseConfig: {
                  name: batch.promises[i].name,
                  url: batch.promises[i].url,
                  form: batch.promises[i].form,
                  config: batch.promises[i].config
                },
                success: false
              })
            );
            dispatch(incrementErrorCount());
          } else {
            dispatch(
              updateResult({
                promiseConfig: {
                  name: batch.promises[i].name,
                  url: batch.promises[i].url,
                  form: batch.promises[i].form,
                  config: batch.promises[i].config
                },
                success: false
              })
            );
            dispatch(incrementErrorCount());
          }
        });
    }
  };

  const getPromiseBatches = (files, groupId, groupModifiers) => {
    if (!groupId) {
      const url = `/admin/shops/${shopId}/reference-pictures`;
      const batchCount = Math.ceil(files.length / batchSize);
      let batches = [];
      // init batches with initialBatchState
      for (let i = 0; i < batchCount; i++) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        batches.push({ done: false, promises: [], doneCount: 0, cancelToken: source });
      }
      files.forEach((file, idx) => {
        const currentBatch = Math.floor(idx / batchSize);

        const form = new FormData();

        form.append('picture', file);
        let promiseConfig = {
          url,
          form,
          name: file.name,
          config: {
            headers: {
              Authorization: AuthService.authHeader(),
              'Content-Type': 'multipart/form-data'
            },
            cancelToken: batches[currentBatch].cancelToken.token
          }
        };
        batches[currentBatch].promises.push(promiseConfig);
      });

      return batches;
    } else {
      const url = `/admin/shops/${shopId}/groups/${groupId}/products`;
      const batchCount = Math.ceil(files.length / batchSize);
      let batches = [];
      // init batches with initialBatchState
      for (let i = 0; i < batchCount; i++) {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        batches.push({ done: false, promises: [], doneCount: 0, cancelToken: source });
      }
      files.forEach((file, idx) => {
        const currentBatch = Math.floor(idx / batchSize);

        const form = new FormData();
        const productDto = {
          name: file.name,
          groupId: groupId,
          isActive: true,
          modifiers: [],
          inheritModifiers: true
        };

        form.append('imageFile', file);
        form.append(
          'productDTO',
          new Blob([JSON.stringify(productDto)], {
            type: 'application/json'
          })
        );
        let promiseConfig = {
          url,
          form,
          name: file.name,
          config: {
            headers: {
              Authorization: AuthService.authHeader(),
              'Content-Type': 'multipart/form-data'
            },
            cancelToken: batches[currentBatch].cancelToken.token
          }
        };
        batches[currentBatch].promises.push(promiseConfig);
      });

      return batches;
    }
  };

  const isOk = (status) => {
    if (status < 300 && status >= 200) return 'true';
    return 'false';
  };

  const processBatch = async (batch, products) => {
    let currentPromises = [];
    let currentUploadResults = [];

    for (let i = 0; i < batch.promises.length; i++) {
      currentUploadResults.push({
        promiseConfig: {
          name: batch.promises[i].name,
          url: batch.promises[i].url,
          form: batch.promises[i].form,
          config: batch.promises[i].config
        },
        success: 'loading'
      });

      currentPromises.push(uploadRequest(batch, i, products));
    }
    dispatch(addBatchResults(currentUploadResults));
    setTimeout(() => {
      batch.cancelToken.cancel('Request timed out after 20s.');
    }, window?._env_?.REACT_APP_UPLOAD_CANCEL_TIMEOUT || 70000);
    try {
      await Promise.allSettled([...currentPromises]);
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(updateBatchResults(currentUploadResults));
    }
  };

  const processBatches = async (batches, refresh, products) => {
    if (batches.length === 0) {
      dispatch(setUploadsLoading(false));
      await refresh();
      return;
    }

    await processBatch(batches.pop(), products);
    processBatches(batches, refresh, products);
  };

  const handleFileUpload = async (event, groupId, groupModifiers, refresh, products) => {
    dispatch(setUploadsLoading(true));
    dispatch(clearUploads());
    const eventFiles = event.target.files;
    const files = getFiles(eventFiles);
    const promiseBatches = getPromiseBatches(files, groupId, groupModifiers);

    processBatches(promiseBatches, refresh, products);

    event.target.value = '';
  };

  const handleRetry = async ({ promiseConfig }) => {
    dispatch(
      updateResult({
        promiseConfig: promiseConfig,
        success: 'loading'
      })
    );
    dispatch(setUploadsLoading(true));
    try {
      const api = await getApi({ baseUrl: window._env_.REACT_APP_QDAK_PRODUCT_URL });
      const CancelToken = axios.CancelToken;
      const source = CancelToken.source();

      const req = await api
        .post(promiseConfig.url, promiseConfig.form, { ...promiseConfig.config, cancelToken: source.token })
        .then((response) => {
          dispatch(
            updateResult({
              promiseConfig: promiseConfig,
              success: isOk(response.status)
            })
          );
          dispatch(incrementCompletedUploads());
          dispatch(decrementErrorCount());
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.data?.message?.split(' ')[0] == '101') {
            dispatch(
              updateResult({
                promiseConfig: promiseConfig,
                success: 'true'
              })
            );
            dispatch(incrementCompletedUploads());
            dispatch(decrementErrorCount());
          } else if (error?.response?.data?.message?.includes('Product limit reached')) {
            errorToast('Képfeltöltési limit elérve! Élesítsd demo boltod!');
            dispatch(
              updateResult({
                promiseConfig: promiseConfig,
                success: 'false'
              })
            );
          } else {
            dispatch(
              updateResult({
                promiseConfig: promiseConfig,
                success: 'false'
              })
            );
          }
        });
      setTimeout(() => {
        if (!req) {
          source.cancel('Timed out by batch process.');
        }
      }, parseInt(window._env_.REACT_APP_AXIOS_TIMEOUT) || 22000);
    } catch (e) {
      dispatch(
        updateResult({
          promiseConfig: promiseConfig,
          success: 'false'
        })
      );
    }

    dispatch(setUploadsLoading(false));
  };
  const batches = useSelector((state) => state.nonPersistedReducers.uploadSliceReducer.batches);

  const handleRetryAll = async (groupId) => {
    dispatch(setUploadsLoading(true));

    batches.forEach(async (batch) => {
      batch.forEach(async ({ promiseConfig, success }) => {
        if (!success || success === 'false')
          await handleRetry({
            promiseConfig: {
              url: promiseConfig.url,
              name: promiseConfig.name,
              form: promiseConfig.form,
              config: promiseConfig.config
            },
            groupId: groupId
          });
      });
    });
    dispatch(setUploadsLoading(false));
  };

  return {
    batchSize,
    batchSizeRef,
    submitbatchSize,
    fileInputRef,
    handleInputClick,
    handleFileUpload,
    handleRetry,
    handleRetryAll
  };
};
